// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/OfertaTable.css */
.OfertaTable {
    width: 100%;
  }
  
  .OfertaTable .MuiTable-root {
    width: 100%;
    border-collapse: collapse;
  }
  
  .OfertaTable .MuiTableHead-root {
    background-color: #f5f5f5;
  }
  
  .OfertaTable .MuiTableCell-root {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
  }
  
  .OfertaTable .MuiTableRow-root:nth-child(even) {
    background-color: #f2f2f2;
  }`, "",{"version":3,"sources":["webpack://./src/components/Marketplace/OfertaTable/OfertaTable.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,WAAW;EACb;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* src/components/OfertaTable.css */\n.OfertaTable {\n    width: 100%;\n  }\n  \n  .OfertaTable .MuiTable-root {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  .OfertaTable .MuiTableHead-root {\n    background-color: #f5f5f5;\n  }\n  \n  .OfertaTable .MuiTableCell-root {\n    padding: 10px;\n    text-align: center;\n    border: 1px solid #ddd;\n  }\n  \n  .OfertaTable .MuiTableRow-root:nth-child(even) {\n    background-color: #f2f2f2;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
