// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Layout.css */

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.layout-content {
  display: flex;
  flex: 1 1;
  position: relative;
}

.main-content {
  flex: 1 1;
  overflow: auto;
  padding: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Commom/Layout/Layout.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,SAAO;EACP,kBAAkB;AACpB;;AAEA;EACE,SAAO;EACP,cAAc;EACd,aAAa;AACf","sourcesContent":["/* src/components/Layout.css */\n\n.layout {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n}\n\n.layout-content {\n  display: flex;\n  flex: 1;\n  position: relative;\n}\n\n.main-content {\n  flex: 1;\n  overflow: auto;\n  padding: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
