// src/components/Produtos.tsx
import React, { useContext, useEffect, useState } from 'react';
import './Produtos.css';
import Layout from '../../components/Commom/Layout/Layout';
import ProdutosTable from '../../components/Marketplace/ProdutosTable/ProdutosTable';
import api from '../../services/api';
import OfertasContext from '../../components/Context/OfertasContext';

const Produtos = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [produtos, setProdutos] = useState([]);
    const [planos, setPlanos] = useState([]);
    const context = useContext(OfertasContext);

    if (!context) {
      throw new Error('Produtos deve estar dentro de um OfertasProvider');
    }
  
    const { ofertas, setOfertas } = context;

    const handleMenuClick = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        api.get('/marketplace/produtos')
            .then(response => {
                setProdutos(response.data); 
                // Extrai todos os planos de todos os produtos
                const todosPlanos = response.data.flatMap((produto: any) => produto.planos);
                setPlanos(todosPlanos);
                
            })
            .catch(error => {
                console.error('Erro ao buscar produtos:', error);
            });
    
        const usuario = JSON.parse(localStorage.getItem('usuario') || '') as { id_marketplace: string };

        api.get(`/marketplace/ofertas/${usuario.id_marketplace}`)
            .then(response => {
                setOfertas(response.data);
            })
            .catch(error => {
                console.error('Erro ao buscar ofertas:', error);
            });
    }, []);
    
    return (
        <Layout onMenuClick={handleMenuClick} credits={100} isOpen={isOpen} onClose={handleClose}>
            <div className="produtos">
                <h1>Produtos</h1>
                <ProdutosTable produtos={produtos} planos={planos} ofertas={ofertas} />
            </div>
        </Layout>
    );
};

export default Produtos;