import React, { useState, useEffect } from 'react';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import './ClientesTable.css';
import api from '../../../services/api';
import { Box, Menu, MenuItem, TablePagination, Tooltip } from '@mui/material';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { notifyError, notifySuccess } from '../../Commom/Notification/Notification';

interface Pagamento {
  data_compra: Date;
  data_vencimento: Date;
}

interface Cliente {
  nome: string;
  email: string;
  doc: string;
  telefone: string;
  channel_adult: boolean;
  id_usuario_dezp: string;
  pagamentos: Pagamento[];
}

const ClientesTable: React.FC = () => {
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const [filtroNome, setFiltroNome] = useState('');
  const [filtroEmail, setFiltroEmail] = useState('');
  const [filtroCpf, setFiltroCpf] = useState('');
  const [filtroVencimento, setFiltroVencimento] = useState<Date | null>(null);
  const [filtroContato, setFiltroContato] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const usuario = JSON.parse(localStorage.getItem('usuario') || '') as { nome: string, id_marketplace: string };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleToggle = async (id: string, status: boolean) => {
    try {
    console.log(id, status)
      await api.post(`/marketplace/${usuario.id_marketplace}/ativar-canais`, {
        status: status,
        id_cliente: id,
        nome: usuario.nome
      });

      notifySuccess('Canais ativado com sucesso!')
    } catch (error) {
      notifyError('Erro ao ativar o canais');
      console.error('Erro ao ativar o marketplace', error);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseToolTip = () => {
    setAnchorEl(null);
  };

  const handleWhatsAppMessage = (cliente: Cliente, message: string) => {
    const phoneNumber = cliente.telefone.replace(/\D/g, ''); // Remove non-digit characters
    const formattedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/+55${phoneNumber}?text=${formattedMessage}`;
    window.open(whatsappUrl, '_blank');
    handleClose();
  };

  useEffect(() => {
    fetchClientes();
  }, [page, rowsPerPage]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApplyFilters = () => {
    fetchClientes();
    handleClose();
  };

  const resetFilters = () => {
    setFiltroNome('');
    setFiltroEmail('');
    setFiltroCpf('');
    setFiltroVencimento(null);
    setFiltroContato('');
  };

  const fetchClientes = async () => {
    try {
      const response = await api.get(`/marketplace/listar-clientes/${usuario.id_marketplace}`, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          nome: filtroNome,
          email: filtroEmail,
          cpf: filtroCpf,
          vencimento: filtroVencimento,
          contato: filtroContato
        }
      });

      setClientes(response.data);
    } catch (error) {
      console.error('Erro ao buscar clientes', error);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="clientes-table-container">
        <Button variant="outlined" color="primary" onClick={handleOpen} style={{ width: '120px', marginLeft: 'auto' }}>Filtros</Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Filtros</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField label="Nome" value={filtroNome} onChange={(event) => setFiltroNome(event.target.value)} />
              <TextField label="Email" value={filtroEmail} onChange={(event) => setFiltroEmail(event.target.value)} />
              <TextField label="CPF" value={filtroCpf} onChange={(event) => setFiltroCpf(event.target.value)} />
              <DatePicker
                label="Vencimento"
                value={filtroVencimento}
                onChange={(date) => setFiltroVencimento(date)}
                format="dd/MM/yyyy"
                clearable
              />
              <TextField label="Contato" value={filtroContato} onChange={(event) => setFiltroContato(event.target.value)} />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetFilters} color="primary">Limpar</Button>
            <Button onClick={handleApplyFilters} color="primary">Aplicar</Button>
          </DialogActions>
        </Dialog>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: '#f5f5f5' }}>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>CPF</TableCell>
                <TableCell>Vencimento</TableCell>
                <TableCell>Contato</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((cliente, index) => (
                <TableRow key={index}>
                  <TableCell>{cliente.nome}</TableCell>
                  <TableCell>{cliente.email}</TableCell>
                  <TableCell>{cliente.doc}</TableCell>
                  <TableCell>
  {cliente.pagamentos && cliente.pagamentos[0] 
    ? new Date(cliente.pagamentos[0].data_vencimento).toLocaleString('pt-BR') 
    : 'N/A'}
</TableCell>
                  <TableCell>
                    {cliente.telefone}
                    <Tooltip title="Enviar mensagem WhatsApp">
                      <Button onClick={handleClick}>
                        <WhatsAppIcon style={{ marginLeft: '10px', color: 'green' }} />
                      </Button>
                    </Tooltip>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseToolTip}
                    >
                      <MenuItem style={{ display: 'block' }} onClick={() => handleWhatsAppMessage(cliente, `Olá ${cliente.nome}, seu serviço conosco está prestes a vencer em ${new Date(cliente.pagamentos[0].data_vencimento).toLocaleString('pt-BR')}. Por favor, considere renovar.`)}>Aviso de vencimento</MenuItem>
                      <MenuItem style={{ display: 'block' }} onClick={() => handleWhatsAppMessage(cliente, `Olá ${cliente.nome}, gostaríamos de ouvir seu feedback sobre nosso serviço. Poderia nos dar um momento do seu tempo?`)}>Feedback de serviço</MenuItem>
                      <MenuItem
                        style={{ display: 'block' }}
                        onClick={() => handleToggle(cliente.id_usuario_dezp, !cliente.channel_adult)}
                      >
                        {cliente.channel_adult === true ? 'Ativar canais adultos' : 'Desativar canais adultos'}
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={clientes.length}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
            labelRowsPerPage="Resultados por página"
          />
        </TableContainer>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default ClientesTable;