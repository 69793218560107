// src/api.ts
import axios from 'axios';
 
const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_API || 'http://localhost:3007',
});

// Adiciona um interceptador de requisição
api.interceptors.request.use((config) => {
  // Obtém o token do .env
  config.headers.Authorization = `Bearer ${process.env.REACT_APP_TOKEN_MARKETPLACE}`;

  return config;
});


// Adiciona um interceptador de requisição
api.interceptors.request.use((config) => {
    // Obtém o token do localStorage
    const token = localStorage.getItem('token');
    // Se o token existir, adiciona ele ao cabeçalho Authorization
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

// Adiciona um interceptador de resposta
api.interceptors.response.use(
    function (response) {
      // Qualquer código de status que esteja dentro do intervalo de 2xx causa que esta função seja acionada
      return response;
    },
    function (error) {

      // Qualquer código de status que esteja fora do intervalo de 2xx causa que esta função seja acionada
      if (error.response && error.response.status === 401) {
        // Adicione aqui a lógica para lidar com o erro 401
        const event = new CustomEvent('unauthorized');
        window.dispatchEvent(event);
      }
  
      // Se você quiser continuar a propagar o erro para outros blocos catch, retorne uma promessa rejeitada
      return Promise.reject(error);
    }
  );



export default api;     