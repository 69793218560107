import React, { useState, useEffect } from 'react';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, Select } from '@material-ui/core';
import './VendasTable.css';
import api from '../../../services/api';
import { Box, MenuItem, TablePagination } from '@mui/material';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'

interface Cliente {
  email: string;
  telefone: string;
}

interface Venda {
  email: string;
  nome_produto: string;
  desc_plano: string;
  data_compra: string;
  valor: number;
  status: string;
  checkout: string;
  metodo: string;
  cliente: Cliente;
}

const VendasTable: React.FC = () => {
  const [vendas, setVendas] = useState<Venda[]>([]);
  const [filtroDataCompra, setFiltroDataCompra] = useState<Date | null>(null);
  const [open, setOpen] = useState(false);
  const [filtroEmail, setFiltroEmail] = useState('');
  const [filtroIdPagamento, setFiltroIdPagamento] = useState('');
  const [filtroCpf, setFiltroCpf] = useState('');
  const [filtroProduto, setFiltroProduto] = useState('Selecione um produto');
  const [filtroPlano, setFiltroPlano] = useState('Selecione um Plano');
  const [filtroStatusPagamento, setFiltroStatusPagamento] = useState('Status de Pagamento');
  const [filtroCheckout, setFiltroCheckout] = useState('Selecione um checkout');
  const [filtroMetodo, setFiltroMetodo] = useState('Selecione um metodo de pagamento');
  const usuario = JSON.parse(localStorage.getItem('usuario') || '') as { id_marketplace: string };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchVendas();
  }, [page, rowsPerPage]);

  const fetchVendas = async () => {
    try {
      const response = await api.get(`/marketplace/listar-vendas/${usuario.id_marketplace}`, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          email: filtroEmail,
          idPagamento: filtroIdPagamento,
          cpf: filtroCpf,
          dataCompra: filtroDataCompra,
          produto: filtroProduto,
          plano: filtroPlano,
          statusPagamento: filtroStatusPagamento,
          checkout: filtroCheckout,
          metodo: filtroMetodo
        }
      });

      setVendas(response.data);
    } catch (error) {
      console.error('Erro ao buscar vendas', error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApplyFilters = () => {
    fetchVendas();
    handleClose();
  };

  const resetFilters = () => {
    setFiltroEmail('');
    setFiltroIdPagamento('');
    setFiltroCpf('');
    setFiltroDataCompra(null);
    setFiltroProduto('Selecione um produto');
    setFiltroPlano('Selecione um Plano');
    setFiltroStatusPagamento('Status de Pagamento');
    setFiltroCheckout('Selecione um checkout');
    setFiltroMetodo('Selecione um metodo de pagamento');
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="vendas-table-container">
        <Button variant="outlined" color="primary" onClick={handleOpen} style={{ width: '120px', marginLeft: 'auto' }}>Filtros</Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Filtros</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField label="Email" value={filtroEmail} onChange={(event) => setFiltroEmail(event.target.value)} />
              <TextField label="ID de Pagamento" value={filtroIdPagamento} onChange={(event) => setFiltroIdPagamento(event.target.value)} />
              <TextField label="CPF" value={filtroCpf} onChange={(event) => setFiltroCpf(event.target.value)} />
              <DatePicker
                label="Data de Compra"
                value={filtroDataCompra}
                onChange={(date) => setFiltroDataCompra(date)}
                format="dd/MM/yyyy"
                clearable
              />
              <Select label="Produto" value={filtroProduto} onChange={(event) => setFiltroProduto(event.target.value as string)}>
                <MenuItem value="Selecione um produto">Selecione um produto</MenuItem>
                <MenuItem value="DezPila">DezPila</MenuItem>
              </Select>
              <Select label="Plano" value={filtroPlano} onChange={(event) => setFiltroPlano(event.target.value as string)}>
                <MenuItem value="Selecione um Plano">Selecione um Plano</MenuItem>
                <MenuItem value="1M">1M</MenuItem>
                 <MenuItem value="1M2T">1M2T</MenuItem>
                <MenuItem value="3M">3M</MenuItem>
                <MenuItem value="3M">3M2T</MenuItem>
                <MenuItem value="3M">3M4T</MenuItem>
                <MenuItem value="6M">6M</MenuItem>
              </Select>
              <Select label="Status de Pagamento" value={filtroStatusPagamento} onChange={(event) => setFiltroStatusPagamento(event.target.value as string)}>
                <MenuItem value="Status de Pagamento">Status de Pagamento</MenuItem>
                <MenuItem value="Pago">Pago</MenuItem>
                <MenuItem value="Aguardando">Aguardando</MenuItem>
              </Select>
              <Select label="Checkout" value={filtroCheckout} onChange={(event) => setFiltroCheckout(event.target.value as string)}>
                <MenuItem value="Selecione um checkout">Selecione um checkout</MenuItem>
                <MenuItem value="MercadoPago">MercadoPago</MenuItem>
                <MenuItem value="PagarMe">PagarMe</MenuItem>
              </Select>
              <Select label="Método" value={filtroMetodo} onChange={(event) => setFiltroMetodo(event.target.value as string)}>
                <MenuItem value="Selecione um metodo de pagamento">Selecione um metodo de pagamento</MenuItem>
                <MenuItem value="PIX">PIX</MenuItem>
                <MenuItem value="Cartão">Cartão</MenuItem>
                <MenuItem value="Boleto">Boleto</MenuItem>
              </Select>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetFilters} color="primary">Redefinir</Button>
            <Button onClick={handleApplyFilters} color="primary">Aplicar Filtros</Button>
          </DialogActions>
        </Dialog>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: '#f5f5f5' }}>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell>Produto</TableCell>
                <TableCell>Plano</TableCell>
                <TableCell>Data Compra</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Checkout</TableCell>
                <TableCell>Método</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vendas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((venda, index) => (
                <TableRow key={index}>
                  <TableCell>{venda.cliente.email}</TableCell>
                  <TableCell>{venda.cliente.telefone}</TableCell>
                  <TableCell>{venda.nome_produto}</TableCell>
                  <TableCell>{venda.desc_plano}</TableCell>
                  <TableCell>{new Date(venda.data_compra).toLocaleString('pt-BR')}</TableCell>
                  <TableCell>{venda.valor}</TableCell>
                  <TableCell>{venda.status}</TableCell>
                  <TableCell>{venda.checkout}</TableCell>
                  <TableCell>{venda.metodo}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        <TablePagination
          component="div"
          count={vendas.length}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
          labelRowsPerPage="Resultados por página"
        />
        </TableContainer>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default VendasTable;