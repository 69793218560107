// src/components/Sidebar/Sidebar.tsx
import { Drawer, List, ListItem, ListItemText, Collapse, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  onMenuClick: () => void;
  credits: number;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose, onMenuClick, credits }) => {
  const [marketplaceOpen, setMarketplaceOpen] = useState(false);
  const [gerenciamentoOpen, setGerenciamentoOpen] = useState(false);

  const [role, setRole] = useState('');

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('usuario') || '') as { cargo: string };
    setRole(user.cargo);
  }, []);

  const handleClose = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    onMenuClick();
  };

  const handleMarketplaceClick = () => {
    setMarketplaceOpen(!marketplaceOpen);
  };

  const handleGerenciamentoClick = () => {
    setGerenciamentoOpen(!gerenciamentoOpen);
  };

  return (
    <Drawer anchor="left" open={isOpen} onClose={handleClose} style={{ zIndex: 1300 }}>
      <div className='sidebar'>
        <List>
          <ListItem button component={Link} to="/dashboard">
            <ListItemText primary={<Typography variant="h6" fontWeight="bold"><b>Dashboard</b></Typography>} />
          </ListItem>
          {(role === 'admin' || role === 'gerente') && (
            <>
              <ListItem button onClick={handleMarketplaceClick}>
                <ListItemText primary={<Typography variant="h6" fontWeight="bold"><b>Marketplace</b></Typography>} />
              </ListItem>
              <Collapse in={marketplaceOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding style={{ marginLeft: '15px' }}>
                  <ListItem button component={Link} to="/produtos">
                    <ListItemText primary={<Typography variant="subtitle1">Produtos</Typography>} />
                  </ListItem>
                  <ListItem button component={Link} to="/logs">
                    <ListItemText primary={<Typography variant="subtitle1">Logs</Typography>} />
                  </ListItem>
                  <ListItem button component={Link} to="/configuracoes-marketplace">
                    <ListItemText primary={<Typography variant="subtitle1">Configurações</Typography>} />
                  </ListItem>
                </List>
              </Collapse>
            </>
          )}
          <ListItem button onClick={handleGerenciamentoClick}>
            <ListItemText primary={<Typography variant="h6" fontWeight="bold"><b>Gerenciamento</b></Typography>} />
          </ListItem>
          <Collapse in={gerenciamentoOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ marginLeft: '15px' }}>
              <ListItem button component={Link} to="/vendas">
                <ListItemText primary={<Typography variant="subtitle1">Vendas</Typography>} />
              </ListItem>
              <ListItem button component={Link} to="/clientes">
                <ListItemText primary={<Typography variant="subtitle1">Clientes</Typography>} />
              </ListItem>
              {(role === 'admin' || role === 'gerente') && (
                <>
                  <ListItem button component={Link} to="/equipe">
                    <ListItemText primary={<Typography variant="subtitle1">Equipe</Typography>} />
                  </ListItem>
                  <ListItem button component={Link} to="/historico">
                    <ListItemText primary={<Typography variant="subtitle1">Histórico de Créditos</Typography>} />
                  </ListItem>
                </>
              )}
            </List>
          </Collapse>
        </List>
      </div>
    </Drawer>
  );
};

export default Sidebar;