// src/components/LineChart.tsx
import React from 'react';
import { Line } from 'react-chartjs-2';
import './LineChart.css';

interface LineChartProps {
    data: {
        labels: string[];
        datasets: {
            data: number[];
            backgroundColor: string;
            borderColor: string;
            fill: boolean;
        }[];
    };
    title: string;
}

const LineChart: React.FC<LineChartProps> = ({ data, title }) => {
    return (
        <div className="line-chart-container">
          <h3>{title}</h3>
          <Line data={data} />
        </div>
      );
    };

export default LineChart;