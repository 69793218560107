// src/components/Header/Header.tsx
import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box, Tooltip } from '@mui/material';
import { Menu as MenuIcon, Logout as LogoutIcon, Settings as SettingsIcon } from '@mui/icons-material';
import logo from '../../../images/tapagu-logo.png';
import './Header.css';
import { MonetizationOn as MonetizationOnIcon } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../../services/api';

interface HeaderProps {
  onMenuClick: () => void;
  credits: number;
}

const Header: React.FC<HeaderProps> = ({ onMenuClick }) => {
  const usuario = JSON.parse(localStorage.getItem('usuario') || '') as { id_marketplace: string, cargo: string };
  const [credits, setCredits] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCredits = async () => {
      try {
        const response = await api.get(`/marketplace/historico-creditos/${usuario.id_marketplace}`);
       
        setCredits(response.data.creditos);
      } catch (error) {
        console.error('Erro ao buscar créditos', error);
      }
    };

    fetchCredits();
    const intervalId = setInterval(fetchCredits, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const handleLogout = () => {
    // Remover os dados do localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.removeItem('nome');

    // Redirecionar para a tela de login
    navigate('/');
  };

  const handleCreditosChange = () => {
     navigate('/comprar/creditos', { state: { userData: { id: usuario.id_marketplace } } });
  }

  return (
    <AppBar position="fixed" className="header" style={{ zIndex: 1301, backgroundColor: '#f5f5f5', color: '#000000' }}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={onMenuClick}>
          <MenuIcon />
        </IconButton>
        <img src={logo.toString()} alt="Logo" style={{ marginLeft: '10px' }} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          { (usuario.cargo === 'admin' || usuario.cargo === 'gerente') && (
            <Tooltip title={
              <Box component="span" whiteSpace="pre-line">
                {'1M = 1 Crédito\n1M2T = 2 Créditos\n3M = 3 Créditos\n3M2T = 6 Créditos\n3M4T = 10 Créditos\n6M = 6 Créditos'}
              </Box>
            } enterTouchDelay={0}>
              <Box onClick={handleCreditosChange} style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'right', display: 'flex', alignItems: 'center' }}>
                  <MonetizationOnIcon sx={{ marginRight: '2px', color: 'green' }} />
                  <span style={{ color: credits < 20 ? 'red' : credits < 50 ? '#f0ad32' : 'black' }}>{credits}</span>
                </Typography>
              </Box>
            </Tooltip>
          )}
          <Link to="/configuracoes" style={{ textDecoration: 'none', color: 'inherit' }}>
            <IconButton color="inherit">
              <SettingsIcon />
            </IconButton>
          </Link>
          <IconButton color="inherit" onClick={handleLogout}>
            <LogoutIcon />
          </IconButton>
        </Box>
      </Toolbar>

    </AppBar>
  );
};

export default Header;