// src/pages/Configuracoes/Configuracoes.jsx
import React, { useState } from 'react';
import './Configuracoes.css';
import Layout from '../../components/Commom/Layout/Layout';
import ConfigPerfil from '../../components/Marketplace/ConfigPerfil/ConfigPerfil';

const Configuracoes = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleMenuClick = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

  return (
    <Layout onMenuClick={handleMenuClick} credits={100} isOpen={isOpen} onClose={handleClose}>
      <div className="configuracoes-container">
        <h1>Configurações</h1>
        <ConfigPerfil />
      </div>
    </Layout>
  );
};

export default Configuracoes;