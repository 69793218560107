// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/ConfigMarketplace/ConfigMarketplace.css */
.config-marketplace-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 60%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px
  }
  
  .save-button {
    margin-top: 20px;
    align-self: center;
  }`, "",{"version":3,"sources":["webpack://./src/components/Marketplace/ConfigMarketplace/ConfigMarketplace.css"],"names":[],"mappings":"AAAA,2DAA2D;AAC3D;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,UAAU;IACV,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB;EACF;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;EACpB","sourcesContent":["/* src/components/ConfigMarketplace/ConfigMarketplace.css */\n.config-marketplace-container {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    width: 60%;\n    margin-top: 20px;\n    margin-left: auto;\n    margin-right: auto;\n  }\n  \n  .input-container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin: 30px\n  }\n  \n  .save-button {\n    margin-top: 20px;\n    align-self: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
