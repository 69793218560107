import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Input } from '@material-ui/core';
import './ResetarSenha.css';
import { Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../../services/api';
import MaskedInput from 'react-text-mask';
import { notifySuccess } from '../../Commom/Notification/Notification';
import Modal from '../../Commom/Modal/Modal';

function TextMaskCustom(props: any) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={value => value ? [/[1-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/] : []}
            placeholderChar={'\u2000'}
            keepCharPositions={true}
        />
    );
}

function ResetarSenha() {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [cpf, setCpf] = useState('');
    const [senha, setSenha] = useState('');
    const [confirmSenha, setConfirmSenha] = useState('');
    const [senhaValida, setSenhaValida] = useState(false);
    const [showAuthenticatorInput, setShowAuthenticatorInput] = useState(false);
    const [authenticatorCode, setAuthenticatorCode] = useState('');
    const navigate = useNavigate();

    const handleSenhaChange = (e: any) => {
        const senha = e.target.value;
        setSenha(senha);

        const senhaRequisitos = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        setSenhaValida(senhaRequisitos.test(senha));
    };

    const handleConfirmSenhaChange = (e: any) => {
        const confirmSenha = e.target.value;
        setConfirmSenha(confirmSenha);

        if (senha !== confirmSenha) {
            // mostrar mensagem de erro
        }
    };

    const handleAuthenticatorCodeChange = (e: any) => {
        setAuthenticatorCode(e.target.value);
    };

    const handleAuthenticatorCodeSubmit = async () => {
        try {
            const response = await api.post('/public/validate-authenticator-code/password', { email, code: authenticatorCode });

            if (response.status === 200) {
                setShowAuthenticatorInput(false);
                setStep(3);
            }
        } catch (error) {
            console.error('Erro ao validar o código 2FA:', error);
        }
    };

    const handleNext = async () => {
        try {
            if (step === 1 && email) {
                const response = await api.post('/public/validar-email', { email });

                if (response.status === 200) {
                    setCpf(response.data.cpf);
                    setStep(2);
                } else if (response.status === 201) {
                    setShowAuthenticatorInput(true);
                }
            } else if (step === 2 && cpf) {
                const response = await api.post('/public/validar-doc', { email, cpf });

                if (response.status === 200) {
                    setStep(3);
                }
            } else if (step === 3 && senha && senha === confirmSenha) {
                const response = await api.post('/public/resetar-senha', { email, newPassword: senha });

                if (response.status === 200) {
                    notifySuccess('Senha redefinida com sucesso!');
                    navigate('/login');

                }
            }
        } catch (error) {
            console.error('Erro ao redefinir senha:', error);
        }
    };

    const handlePrevious = () => {
        if (step === 2) {
            setStep(1);
        } else if (step === 3) {
            setStep(2);
        }
    };
    return (
        <Container maxWidth="sm" className="resetar-senha-container">
            <Typography variant="h4" component="h1" gutterBottom align='center'>
                Resetar Senha
            </Typography>
            {step === 1 && (
                <div>
                    <Typography variant='h6' component='h5' style={{ fontStyle: 'italic', color: '#d4d4d4' }}>Informe o email para recuperação de senha!</Typography>
                    <TextField
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        label="Email"
                        variant="outlined"
                        fullWidth
                    />
                </div>
            )}
            {step === 2 && (
                <div>
                    <Typography variant='h6' component='h5' style={{ fontStyle: 'italic', color: '#d4d4d4' }}>Informe o restante do documento cadastrado.</Typography>
                    <TextField
                        value={cpf}
                        onChange={(e) => setCpf(e.target.value)}
                        name="cpf"
                        id="formatted-text-mask-input"
                        InputProps={{
                            inputComponent: TextMaskCustom,
                        }}
                        variant="outlined"
                        fullWidth
                        placeholder={cpf}
                    />
                </div>
            )}
            {step === 3 && (
                <>
                    <TextField
                        type="password"
                        value={senha}
                        onChange={handleSenhaChange}
                        label="Nova Senha"
                        variant="outlined"
                        fullWidth
                        error={!senhaValida}
                        helperText={!senhaValida && "A senha deve ter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial."}
                    />
                    <TextField
                        type="password"
                        value={confirmSenha}
                        onChange={handleConfirmSenhaChange}
                        label="Confirmar Senha"
                        variant="outlined"
                        fullWidth
                        error={senha !== confirmSenha}
                        helperText={senha !== confirmSenha && "As senhas não coincidem."}
                    />
                </>
            )}
            <Box className='resetar-group-button'>
                {step < 3 && (
                    <Button variant="contained" color="primary" fullWidth onClick={handleNext}>
                        Próximo
                    </Button>
                )}
                {step >= 3 && (
                    <Button variant="contained" color="primary" onClick={handleNext}>
                        Concluir
                    </Button>
                )}
                {step === 1 && (
                    <Link to='/login'>
                        <Button variant="contained" color="secondary" fullWidth onClick={handlePrevious}>
                            Acessar
                        </Button>
                    </Link>
                )}
                {step > 1 && step < 3 && (
                    <Button variant="contained" color="secondary" fullWidth onClick={handlePrevious}>
                        Voltar
                    </Button>
                )}
            </Box>
            <Modal
                open={showAuthenticatorInput}
                title="Autenticação em duas etapas"
                onClose={() => setShowAuthenticatorInput(false)}
            >
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Input name="authenticatorCode" placeholder="Código do Google Authenticator" className="input-field" value={authenticatorCode} onChange={handleAuthenticatorCodeChange} />
                    <Button color="primary" variant="contained" className="login-button" onClick={handleAuthenticatorCodeSubmit}>Validar Código</Button>
                </Box>
            </Modal>
        </Container>
    );
}

export default ResetarSenha;