// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal.css */
.dialog {
    width: 500px;
    margin: auto;
}

.dialog-title {
    background-color: #f5f5f5;
    color: #333;
    padding: 20px;
    font-size: 20px;
    font-weight: bold;
}

.dialog-content {
    padding: 20px;
    font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/Commom/Modal/Modal.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB","sourcesContent":["/* Modal.css */\n.dialog {\n    width: 500px;\n    margin: auto;\n}\n\n.dialog-title {\n    background-color: #f5f5f5;\n    color: #333;\n    padding: 20px;\n    font-size: 20px;\n    font-weight: bold;\n}\n\n.dialog-content {\n    padding: 20px;\n    font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
