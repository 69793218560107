// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Login/Login.css */
.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.logo {
  width: 300px;
  height: 80px;
}

.form-login {
  width: 400px;
  margin-bottom: 20px;
}

 .input-field {
  width: 100%;
  margin-bottom: 25px;
}

 .login-button {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.box-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.box-wrapper .login-text {
  text-align: center;
  color: #252525;
  text-decoration: none;
  margin-right: 30px;
  margin-left: 30px;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Marketplace/Login/Login.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;CAEC;EACC,WAAW;EACX,mBAAmB;AACrB;;CAEC;EACC,WAAW;EACX,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,qBAAqB;EACrB,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":["/* src/components/Login/Login.css */\n.login-wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n}\n\n.logo {\n  width: 300px;\n  height: 80px;\n}\n\n.form-login {\n  width: 400px;\n  margin-bottom: 20px;\n}\n\n .input-field {\n  width: 100%;\n  margin-bottom: 25px;\n}\n\n .login-button {\n  width: 100%;\n  margin-bottom: 10px;\n  margin-top: 10px;\n}\n\n.box-wrapper {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n}\n\n.box-wrapper .login-text {\n  text-align: center;\n  color: #252525;\n  text-decoration: none;\n  margin-right: 30px;\n  margin-left: 30px;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
