// src/App.tsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { OfertasProvider } from './components/Context/OfertasContext';
import RoutesComponent from './routes/RoutesComponent';

function App() {
  const navigate = useNavigate();

 

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <OfertasProvider>
        <RoutesComponent />
      </OfertasProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;