// src/pages/Vendas/Vendas.tsx
import React, { useState } from 'react';
import './Equipe.css';
import Layout from '../../components/Commom/Layout/Layout';
import EquipeTable from '../../components/Marketplace/EquipeTable/EquipeTable';

const Equipe = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleMenuClick = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Layout onMenuClick={handleMenuClick} credits={100} isOpen={isOpen} onClose={handleClose}>
            <div className="equipe">
                <h1>Equipe</h1>
                <EquipeTable />
            </div>
        </Layout>
    );
};

export default Equipe;