import React, { useState } from 'react';
import { Button, FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@mui/material';
import Modal from '../Modal/Modal';
import api from '../../../services/api';
import { notifyError, notifySuccess } from '../Notification/Notification';

interface TermosPoliticasProps {
    open: boolean;
    onClose: () => void;
}

const TermosPoliticas: React.FC<TermosPoliticasProps> = ({ open, onClose }) => {
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
    const usuario = JSON.parse(localStorage.getItem('usuario') || '') as { id_marketplace: string };
    
    const handleCloseModal = () => {
        if (hasAcceptedTerms) {
            const usuario = JSON.parse(localStorage.getItem('usuario') || '') as { id_marketplace: string, cargo: string, has_accepted_terms: Date };
            usuario.has_accepted_terms = new Date(); // Altere para a data atual
            localStorage.setItem('usuario', JSON.stringify(usuario)); // Armazene o objeto atualizado de volta no localStorage
            onClose();
        }
    };

    const handleAcceptTerms = async (event: any) => {
        setHasAcceptedTerms(event.target.checked);

        try {
            await api.post(`/marketplace/${usuario.id_marketplace}/has-accepted-terms`);

            localStorage.setItem('has_accepted_terms', String(event.target.checked));
            notifySuccess('Termo aceito com sucesso!');
        } catch (error) {
            notifyError('Erro ao aceitar os termos');
            console.error('Erro ao aceitar os termos', error);
        }
    };

    return (
        <Modal open={open} title="Regras e Políticas da Plataforma" onClose={handleCloseModal}>
            <div className='dashboard-modal'>
                <h3><b>Transações e Pagamentos</b></h3>
                <p>- Todas as transações devem ser realizadas exclusivamente através da plataforma.</p>
                <p>- Tentativas de processar pagamentos fora do sistema serão consideradas violações dos termos de uso e podem resultar em penalidades</p>
                <p>- Os pagamentos são processados em até 5 minutos após a confirmação.</p>

                <h3><b>Estornos</b></h3>
                <p>- Evite realizar estornos diretamente na sua conta do MercadoPago, pois não nos responsabilizamos por quaisquer consequências decorrentes dessas ações. </p>
                <p>- Solicitações de estorno devem ser feitas pelo cliente dentro de um prazo máximo de 7 dias após a compra.</p>

                <h3><b>Afiliados</b></h3>
                <p>- Afiliados são responsáveis por todas as atividades em suas contas.</p>
                <p>- Mantenha suas credenciais de login seguras.</p>
                <p>- As configurações personalizadas e a validação de chaves de integração com outras plataformas são de responsabilidade do afiliado.</p>

                <h3><b>Créditos</b></h3>
                <p>- Créditos podem ser transferidos apenas entre afiliados e não são reembolsáveis.</p>
                <p>- O status dos créditos pode ser verificado no painel de controle.</p>
                <p>- A plataforma não notifica sobre a expiração dos créditos. Contas sem créditos terão o checkout desativado.</p>

                <h3><b>Segurança e Acesso</b></h3>
                <p>- O compartilhamento de credenciais de acesso com terceiros é proibido.</p>
                <p>- Após três tentativas de login incorretas, a conta será bloqueada temporariamente por 30 minutos.</p>
                <p>- O login é permitido em apenas um dispositivo por vez.</p>

                <h3><b>Bloqueio e Suspensão de Conta</b></h3>
                <p>- Atividades suspeitas ou inusuais podem resultar na suspensão temporária da conta para investigação.</p>
                <p>- Contas inativas por mais de 60 dias serão excluídas, e os créditos remanescentes serão perdidos.</p>

                <h3><b>Suporte ao Cliente</b></h3>
                <p>- O suporte ao cliente está disponível todos os dias da semana, das 6h às 22h.</p>

                <h3><b>Suporte ao Afiliado</b></h3>
                <p>- O suporte ao afiliado está disponível nos dias úteis, das 9h às 18h, pelo telefone (82) 99123-1945.</p>
                <p>- Solicitações fora desse horário serão processadas no próximo dia útil..</p>

                <FormControlLabel
                    control={<Checkbox checked={hasAcceptedTerms} onChange={handleAcceptTerms} />}
                    label="Eu aceito os termos e condições"
                />
                <div className="button-container">
                    <Button variant="contained" color="primary" onClick={handleCloseModal} disabled={!hasAcceptedTerms}>
                        Fechar
                    </Button>
                </div>
            </div>
        </Modal>
    )
};

export default TermosPoliticas;