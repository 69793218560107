// src/components/ProdutoDetalhes.tsx
import React, { useContext, useState } from 'react';
import { AppBar, Tabs, Tab, Card, CardContent, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Switch } from '@material-ui/core';
import './ProdutoDetalhes.css';
import OfertaTable from '../OfertaTable/OfertaTable';
import { Button } from '@mui/material';
import Pixel from '../Pixel/Pixel';
import OfertaNova from '../OfertaNova/OfertaNova';
import logo from '../../../images/dezp-logo.png';
import OfertasContext from '../../Context/OfertasContext';

interface Produto {
  id: number;
  nome: string;
  descricao: string;
  foto: string;
}

interface Plano {
  descricao: string;
  valor: number;
  id_produto: number;
}

interface Oferta {
  id_produto: number;
  plano: string;
  url_checkout: string;
  valor: number;
  status: string;
}

interface ProdutoDetalhesProps {
  produto: Produto;
  planos: Plano[];
  ofertas: Oferta[];
}

const ProdutoDetalhes: React.FC<ProdutoDetalhesProps> = ({ produto, planos }) => {
  const [value, setValue] = React.useState(0);
  const [novaOferta, setNovaOferta] = useState(false);
  const { ofertas } = useContext(OfertasContext) || {};

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };


  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <AppBar position="static" className='produto-detalhes-tab' style={{ backgroundColor: '#f5f5f5', color: '#000000' }} >
        <Tabs value={value} onChange={handleChange} centered >
          <Tab label="Detalhes" />
          <Tab label="Ofertas" />
          <Tab label="Pixel" />
        </Tabs>
      </AppBar>
      <Box flexGrow={1} overflow="auto" p={2}>
        {value === 0 &&
          <Card className="produto-detalhes-container" >
            <CardContent style={{ width: '100%' }}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box display="flex" flexDirection="column" alignItems="center" style={{ width: '100%' }}>
                  <img src={produto.foto} className="logo" />
                  {/* <img src={logo.toString()} className="logo-produto-detalhes" /> */}
                  <Typography variant="h5" component="h2" className="MuiTypography-h5">
                    {produto.nome}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {produto.descricao}
                  </Typography>
                </Box>
                <TableContainer className="TableContainer" style={{ margin: '10px' }}>
                  <Table>
                    <TableHead className="TableHead">
                      <TableRow>
                        <TableCell className="TableCell" style={{ textAlign: 'center' }}>Nome do Plano</TableCell>
                        <TableCell className="TableCell" style={{ textAlign: 'center' }}>Valor Tabelado</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {planos && planos.filter(plano => plano.id_produto === produto.id).map((plano) => (
                        <TableRow key={plano.descricao}>
                          <TableCell className="TableCell" style={{ textAlign: 'center' }}>{plano.descricao}</TableCell>
                          <TableCell className="TableCell" style={{ textAlign: 'center' }}>R$ {plano.valor}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </CardContent>
          </Card>
        }
        {value === 1 &&
          <Box display="flex" flexDirection="column">
            <Button variant="contained" color="primary" style={{ width: '160px', height: '40px', marginBottom: '10px', backgroundColor: '#9c27b0', color: '#f5f5f5' }} onClick={() => setNovaOferta(true)}>+ Nova Oferta</Button>
            {novaOferta ? <OfertaNova voltar={() => setNovaOferta(false)} /> : <OfertaTable id_produto={produto.id} ofertas={ofertas ?? []} />}
          </Box>
        }
        {value === 2 && <Pixel produto={produto} />}
      </Box>
    </Box>
  );
};

export default ProdutoDetalhes;