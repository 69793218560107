// src/pages/Configuracoes/Configuracoes.jsx
import React, { useState } from 'react';
import './ConfiguracoesMarketplace.css';
import Layout from '../../components/Commom/Layout/Layout';
import ConfigMarketplace from '../../components/Marketplace/ConfigMarketplace/ConfigMarketplace';

const ConfiguracoesMarketplace = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleMenuClick = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

  return (
    <Layout onMenuClick={handleMenuClick} credits={100} isOpen={isOpen} onClose={handleClose}>
      <div className="configuracoes-marketplace-container">
        <h1>Configurações</h1>
        <ConfigMarketplace />
      </div>
    </Layout>
  );
};

export default ConfiguracoesMarketplace;