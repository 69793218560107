// src/components/Dashboard.tsx
import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import Layout from '../../components/Commom/Layout/Layout';
import Summary from '../../components/Marketplace/Bi/Summary/Summary';
import PieChart from '../../components/Marketplace/Bi/PieChart/PieChart';
import LineChart from '../../components/Marketplace/Bi/LineChart/LineChart';
import api from '../../services/api';
import { DatePicker } from '@material-ui/pickers';
import TermosPoliticas from '../../components/Commom/TermosPoliticas/TermosPoliticas';
import WelcomeModal from '../../components/Commom/WelcomeModal/WelcomeModal';

type ChartData = {
    labels: string[];
    datasets: {
        data: number[];
        backgroundColor: string[];
    }[];
};

type LineChartData = {
    labels: string[];
    datasets: {
        data: number[];
        label: string;
        fill: boolean;
        borderColor: string;
    }[];
};

const Dashboard = () => {
    const usuario = JSON.parse(localStorage.getItem('usuario') || '') as { id_marketplace: string, cargo: string, has_accepted_terms: boolean };
    const [isOpen, setIsOpen] = useState(false);
    const [summaryData, setSummaryData] = useState(null);
    const [period, setPeriod] = useState('today');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const nomeUsuario = localStorage.getItem('nome');
    const [pieChartDataStatus, setPieChartDataStatus] = useState<ChartData | null>(null);
    const [pieChartDataPaymentMethod, setPieChartDataPaymentMethod] = useState<ChartData | null>(null);
    const [pieChartDataBestPlans, setPieChartDataBestPlans] = useState<ChartData | null>(null);
    const [lineChartDataSales, setLineChartDataSales] = useState<LineChartData | null>(null);
    const isSupport = usuario.cargo === 'suporte';
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(usuario.has_accepted_terms === null || usuario.has_accepted_terms === false );


    useEffect(() => {
        fetchSummaryData();
        fetchPieChartDataStatus();
        fetchPieChartDataPaymentMethod();
        fetchPieChartDataBestPlans();
        fetchLineChartDataSales();
    }, [period, startDate, endDate]);

    useEffect(() => {
        if (usuario.has_accepted_terms === null || usuario.has_accepted_terms === false ) {
            setIsModalOpen(true);
        }
    }, []);

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };


    const fetchLineChartDataSales = async () => {
        if (isSupport) return

        let url = `/marketplace/dashboard/${usuario.id_marketplace}/vendasPorData?dateFilter=${period}`;
        if (period === 'between') {
            url += `&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
        }

        try {
            const response = await api.get(url);
            const data = {
                labels: response.data.labels,
                datasets: [
                    {
                        data: response.data.data,
                        label: 'Vendas',
                        fill: false,
                        borderColor: 'rgb(75, 192, 192)',
                    }
                ]
            };
            setLineChartDataSales(data);
        } catch (error) {
            console.error('Erro ao buscar dados de vendas por data', error);
        }
    };

    const fetchPieChartDataBestPlans = async () => {
        if (isSupport) return

        let url = `/marketplace/dashboard/${usuario.id_marketplace}/planosMaisVendidos?dateFilter=${period}`;
        if (period === 'between') {
            url += `&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
        }

        try {
            const response = await api.get(url);
            const data = {
                labels: response.data.map((item: { desc_plano: string }) => item.desc_plano),
                datasets: [
                    {
                        data: response.data.map((item: { count: number }) => item.count),
                        backgroundColor: COLORS,
                    }
                ]
            };
            setPieChartDataBestPlans(data);
        } catch (error) {
            console.error('Erro ao buscar dados de planos mais vendidos', error);
        }
    };

    const fetchPieChartDataPaymentMethod = async () => {
        if (isSupport) return

        let url = `/marketplace/dashboard/${usuario.id_marketplace}/vendaPorMetodo?dateFilter=${period}`;
        if (period === 'between') {
            url += `&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
        }

        try {
            const response = await api.get(url);
            const data = {
                labels: response.data.map((item: { metodo: string }) => item.metodo),
                datasets: [
                    {
                        data: response.data.map((item: { count: number }) => item.count),
                        backgroundColor: COLORS,
                    }
                ]
            };
            setPieChartDataPaymentMethod(data);
        } catch (error) {
            console.error('Erro ao buscar dados de venda por método de pagamento', error);
        }
    };

    const fetchPieChartDataStatus = async () => {
        if (isSupport) return

        let url = `/marketplace/dashboard/${usuario.id_marketplace}/vendaPorStatus?dateFilter=${period}`;
        if (period === 'between') {
            url += `&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
        }

        try {
            const response = await api.get(url);
            const data = {
                labels: response.data.map((item: { status: string }) => item.status),
                datasets: [
                    {
                        data: response.data.map((item: { count: number }) => item.count),
                        backgroundColor: COLORS,
                    }
                ]
            };
            setPieChartDataStatus(data);
        } catch (error) {
            console.error('Erro ao buscar dados de venda por status', error);
        }
    };

    const fetchSummaryData = async () => {
        if (isSupport) return

        let url = `/marketplace/dashboard/${usuario.id_marketplace}/summary?dateFilter=${period}`;
        if (period === 'between') {
            url += `&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
        }

        try {
            const response = await api.get(url);
            setSummaryData(response.data);
        } catch (error) {
            console.error('Erro ao buscar dados do resumo', error);
        }
    };

    const handleMenuClick = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const COLORS = ['#0074f0', '#3cb1c5', '#7157d9', '#4da8d1', '#463d91', '#2a8b9a'];


    const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setPeriod(e.target.value);
        fetchSummaryData();
    };

    return (
        <Layout onMenuClick={handleMenuClick} credits={100} isOpen={isOpen} onClose={handleClose}>
            <div className={'dashboard'}>
                <span className='dashboard-span'>Bem vindo, {nomeUsuario}</span>
                <div className={`dashboard ${isSupport ? 'blurred' : ''}`}>
                    <div className="dashboard-summary" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <select value={period} onChange={handlePeriodChange}>
                            <option value="today">Hoje</option>
                            <option value="last7days">Últimos 7 dias</option>
                            <option value="last30days">Últimos 30 dias</option>
                            <option value="between">Período entre</option>
                        </select>
                        {period === 'between' && (
                            <div>
                                <DatePicker value={startDate} onChange={date => setStartDate(date || new Date())} />
                                <DatePicker value={endDate} onChange={date => setEndDate(date || new Date())} />
                            </div>
                        )}
                        {summaryData && <Summary {...summaryData as any} />}
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {pieChartDataStatus && <PieChart data={pieChartDataStatus} title="Venda por status" />}
                            {pieChartDataPaymentMethod && <PieChart data={pieChartDataPaymentMethod} title="Método de Pagamento" />}
                            {pieChartDataBestPlans && <PieChart data={pieChartDataBestPlans} title="Planos mais Vendidos" />}
                        </div>
                        {lineChartDataSales && <LineChart data={{ ...lineChartDataSales, datasets: lineChartDataSales.datasets.map(dataset => ({ ...dataset, backgroundColor: '' })) }} title="Vendas por Data" />}
                    </div>
                </div>
            </div>
            <div>
                <TermosPoliticas open={isModalOpen} onClose={handleCloseModal} />
            </div>
            {isWelcomeModalOpen && <WelcomeModal/>} 
        </Layout>
    );
};

export default Dashboard;