// OfertasContext.tsx
import React, { useState, createContext } from 'react';

type OfertasContextType = {
  ofertas: any[];
  setOfertas: (ofertas: any[]) => void;
};

const OfertasContext = createContext<OfertasContextType | undefined>(undefined);

export const OfertasProvider = ({ children }: any) => {
  const [ofertas, setOfertas] = useState<any[]>([]);

  return (
    <OfertasContext.Provider value={{ ofertas, setOfertas }}>
      {children}
    </OfertasContext.Provider>
  );
};

export default OfertasContext;