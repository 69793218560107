// src/components/Modal.tsx
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import React from 'react';
import './Modal.css';

interface ModalProps {
  open: boolean;
  title: string;
  children: React.ReactNode;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ open, title, children, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} className="dialog">
      <DialogTitle className="dialog-title">{title}</DialogTitle>
      <DialogContent className="dialog-content">{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;