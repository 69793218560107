// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/OfertaNova/OfertaNova.css */
.oferta-nova-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .oferta-nova-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Marketplace/OfertaNova/OfertaNova.css"],"names":[],"mappings":"AAAA,6CAA6C;AAC7C;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;EAClB","sourcesContent":["/* src/components/OfertaNova/OfertaNova.css */\n.oferta-nova-container {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n  }\n  \n  .oferta-nova-buttons {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 20px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
