// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/ProdutoDetalhes.css */
.produto-detalhes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.produto-detalhes-container .logo {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
}

.produto-detalhes-container .MuiTypography-h5 {
    margin-bottom: 10px;
}

.produto-detalhes-tab {
    margin-top: 40px;
    height: 55px;
    margin-bottom: 40px;
}

.produto-detalhes-tab .PrivateTabIndicator-colorSecondary-5 {
    background-color: #7610c931;
    border-radius: 5px;
}

.logo-produto-detalhes {
    width: 100px;
    height: auto;
  }

  .TableHead {
    background-color: #f5f5f5;
  }
  

`, "",{"version":3,"sources":["webpack://./src/components/Marketplace/ProdutoDetalhes/ProdutoDetalhes.css"],"names":[],"mappings":"AAAA,uCAAuC;AACvC;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;EACzB;;AAEF;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;IAC3B,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* src/components/ProdutoDetalhes.css */\n.produto-detalhes-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n  }\n\n.produto-detalhes-container .logo {\n    width: 100px;\n    height: 100px;\n    margin-bottom: 20px;\n}\n\n.produto-detalhes-container .MuiTypography-h5 {\n    margin-bottom: 10px;\n}\n\n.produto-detalhes-tab {\n    margin-top: 40px;\n    height: 55px;\n    margin-bottom: 40px;\n}\n\n.produto-detalhes-tab .PrivateTabIndicator-colorSecondary-5 {\n    background-color: #7610c931;\n    border-radius: 5px;\n}\n\n.logo-produto-detalhes {\n    width: 100px;\n    height: auto;\n  }\n\n  .TableHead {\n    background-color: #f5f5f5;\n  }\n  \n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
