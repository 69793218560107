// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Estilo para o container do formulário */
.cadastro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Altura total da tela */
  }
  
  /* Estilo para o formulário */
  .cadastro-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px; /* Largura máxima do formulário */
  }

.cadastro-form .cadastro-group-button {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Centralize os botões horizontalmente */
    gap: 10px; /* Crie um espaçamento de 10px entre os botões */
}
  
  /* Estilo para os campos do formulário */
  .cadastro-field {
    width: 100%;
    height: 30px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
  }
`, "",{"version":3,"sources":["webpack://./src/components/Marketplace/Cadastro/Cadastro.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa,EAAE,yBAAyB;EAC1C;;EAEA,6BAA6B;EAC7B;IACE,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,gBAAgB,EAAE,iCAAiC;EACrD;;AAEF;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB,EAAE,yCAAyC;IAClE,SAAS,EAAE,gDAAgD;AAC/D;;EAEE,wCAAwC;EACxC;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;EACjB","sourcesContent":["/* Estilo para o container do formulário */\n.cadastro-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh; /* Altura total da tela */\n  }\n  \n  /* Estilo para o formulário */\n  .cadastro-form {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    max-width: 400px; /* Largura máxima do formulário */\n  }\n\n.cadastro-form .cadastro-group-button {\n    display: flex;\n    flex-direction: row;\n    justify-content: center; /* Centralize os botões horizontalmente */\n    gap: 10px; /* Crie um espaçamento de 10px entre os botões */\n}\n  \n  /* Estilo para os campos do formulário */\n  .cadastro-field {\n    width: 100%;\n    height: 30px;\n    padding: 10px;\n    margin-bottom: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    text-align: center;\n    font-size: 16px;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
