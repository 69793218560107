// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/LineChart.css */
.line-chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 1200px;
  }
  
  .line-chart-container h3 {
    margin-bottom: 20px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Marketplace/Bi/LineChart/LineChart.css"],"names":[],"mappings":"AAAA,iCAAiC;AACjC;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,aAAa;EACf;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":["/* src/components/LineChart.css */\n.line-chart-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n    width: 1200px;\n  }\n  \n  .line-chart-container h3 {\n    margin-bottom: 20px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
