// src/components/Layout.tsx
import React from 'react';
import './Layout.css';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';

interface LayoutProps {
  onMenuClick: () => void;
  credits: number;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children, onMenuClick, credits, isOpen, onClose }) => {
  return (
    <div className="layout">
      <Header onMenuClick={onMenuClick} credits={credits} />
      <div className="layout-content">
        <Sidebar isOpen={isOpen} onClose={onClose} onMenuClick={onMenuClick} credits={credits}/>
        <main className="main-content">{children}</main>
      </div>
    </div>
  );
};

export default Layout;