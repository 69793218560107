import React, { useState } from 'react';
import { Box, Button, Input, Typography } from '@mui/material';
import './Login.css';
import logo from '../../../images/tapagu-logo.png';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../../services/api';
import { notifyError, notifySuccess } from '../../Commom/Notification/Notification';
import Modal from '../../Commom/Modal/Modal';

const Login: React.FC = () => {
  const [values, setValues] = useState({ email: '', senha: '' });
  const [showAuthenticatorInput, setShowAuthenticatorInput] = useState(false);
  const [authenticatorCode, setAuthenticatorCode] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleLogin = async () => {
    try {
      const response = await api.post('/public/login', values);

      if (response.status === 200) {
        notifySuccess('Login efetuado com sucesso!');
        const { token, usuario, nome, cfg } = response.data;

        // Save token and usuario information in localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('usuario', JSON.stringify(usuario));
        localStorage.setItem('nome', nome);
        localStorage.setItem('cfg', JSON.stringify(cfg));
        localStorage.setItem('has_accepted_terms', JSON.stringify(response.data.usuario.has_accepted_terms));


        navigate('/dashboard');
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        setShowAuthenticatorInput(true);
      } else if (error.response.status === 403) {
        notifyError('Usuário não está permitido!');
      } else if (error.response.status === 400) {
        notifyError('Email ou senha inválidos!');
      } else {
        notifyError('Erro ao fazer login!');
      }
      console.error('Erro ao fazer login:', error);
    }
  };

  const handleAuthenticatorCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthenticatorCode(event.target.value);
  };

  const handleAuthenticatorCodeSubmit = async () => {
    try {
      // Aqui você deve fazer a chamada para a API para validar o código de autenticação
      const response = await api.post('/public/validate-authenticator-code', { code: authenticatorCode, email: values.email });

      if (response.status === 200) {
        notifySuccess('Código de autenticação validado com sucesso!');

        // Save token and usuario information in localStorage
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('usuario', JSON.stringify(response.data.usuario));
        localStorage.setItem('nome', response.data.nome);
        localStorage.setItem('cfg', response.data.cfg);
        localStorage.setItem('has_accepted_terms', JSON.stringify(response.data.usuario.has_accepted_terms));

        navigate('/dashboard');
      }
    } catch (error: any) {
      notifyError('Erro ao validar o código de autenticação!');
      console.error('Erro ao validar o código de autenticação:', error);
    }
  };

  return (
    <Box className="login-wrapper">
   <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      <p style={{ marginBottom: '1px' }}>checkout</p>
      <img className="logo" src={logo.toString()} alt="Logo" />
    </div>
      <p>LOGIN</p>
      <form className='form-login'>
        <Input name="email" placeholder="Email" className="input-field" value={values.email} onChange={handleInputChange} />
        <Input name="senha" placeholder="Senha" className="input-field" type='password' value={values.senha} onChange={handleInputChange} />
        <Button color="primary" variant="contained" className="login-button" onClick={handleLogin}>Entrar</Button>
      </form>
      <div className='box-wrapper'>
        <Link to="/esqueceu-senha" style={{ textDecoration: 'none' }}><Typography variant="body1" className="login-text">Esqueceu a senha?</Typography></Link>
        <Link to="/cadastro" style={{ textDecoration: 'none' }}><Typography variant="body1" className="login-text">Não possui cadastro?</Typography></Link>
      </div>
      <Modal
        open={showAuthenticatorInput}
        title="Autenticação em duas etapas"
        onClose={() => setShowAuthenticatorInput(false)}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Input name="authenticatorCode" placeholder="Código do Google Authenticator" className="input-field" value={authenticatorCode} onChange={handleAuthenticatorCodeChange} />
          <Button color="primary" variant="contained" className="login-button" onClick={handleAuthenticatorCodeSubmit}>Validar Código</Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Login;