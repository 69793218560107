import React, { useState, useEffect } from 'react';
import { TextField, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff, FileCopy, HelpOutline } from '@material-ui/icons';
import './ConfigMarketplace.css';
import { Button } from '@mui/material';
import api from '../../../services/api';
import { notifyError, notifySuccess } from '../../Commom/Notification/Notification';

const ConfigMarketplace: React.FC = () => {
    const [mercadoPagoCode, setMercadoPagoCode] = useState('');
    const [webhookUrl, setWebhookUrl] = useState('');
    const [marketplaceToken, setMarketplaceToken] = useState('');
    const [showToken, setShowToken] = useState(false);
    const usuario = JSON.parse(localStorage.getItem('usuario') || '') as { nome: string, id_marketplace: string };

    useEffect(() => {
        const fetchConfig = async () => {
            try {
                const response = await api.get(`/marketplace/listar-cfg/${usuario.id_marketplace}`);
                setMercadoPagoCode(response.data.mercado_pago);
                setWebhookUrl(response.data.webhook_pagarme);
                setMarketplaceToken(response.data.token_marketplace);
            } catch (error) {
                console.error('Erro ao buscar configurações do marketplace', error);
            }
        };

        fetchConfig();
    }, []);

    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    const handleSave = async () => {
        try {
            const response = await api.post('/marketplace/criar-atualizar-cfg', {
                mercado_pago: mercadoPagoCode,
                webhook_pagarme: webhookUrl,
                token_marketplace: marketplaceToken,
                id_marketplace: usuario.id_marketplace,
                nome: usuario.nome
            });

            if (response.status === 200) {
                notifySuccess('Configurações atualizadas com sucesso')
            }
        } catch (error) {
            notifyError('Erro ao atualizar configurações do marketplace')
            console.error('Erro ao atualizar configurações do marketplace', error);
        }
    };

    return (
        <div className="config-marketplace-container">
            <div className="input-container">
                <TextField fullWidth label="Mercado Pago" value={mercadoPagoCode} onChange={(event) => setMercadoPagoCode(event.target.value)} />
                <a href='/tutorial/mercado-pago' target='_blank' rel='noopener noreferrer'>
                    <IconButton>
                        <HelpOutline />
                    </IconButton>
                </a>
            </div>
            <div className="input-container">
                <TextField fullWidth disabled label="Webhook Pagarme" value={webhookUrl} />
                <IconButton onClick={() => handleCopy(webhookUrl)}>
                    <FileCopy />
                </IconButton>
                <a href='/tutorial/pagar-me' target='_blank' rel='noopener noreferrer'>
                    <IconButton>
                        <HelpOutline />
                    </IconButton>
                </a>
            </div>
            <div className="input-container">
                <TextField fullWidth disabled type={showToken ? 'text' : 'password'} label="Token do Marketplace" value={marketplaceToken} />
                <IconButton onClick={() => setShowToken(!showToken)}>
                    {showToken ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                <IconButton onClick={() => handleCopy(marketplaceToken)}>
                    <FileCopy />
                </IconButton>
            </div>
            <Button variant="contained" color="primary" className="save-button" onClick={handleSave}>Salvar</Button>
        </div>
    );
};

export default ConfigMarketplace;