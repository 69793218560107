// src/pages/Vendas/Vendas.tsx
import React, { useState } from 'react';
import './Clientes.css';
import Layout from '../../components/Commom/Layout/Layout';
import ClientesTable from '../../components/Marketplace/ClientesTable/ClientesTable';

const Clientes = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleMenuClick = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Layout onMenuClick={handleMenuClick} credits={100} isOpen={isOpen} onClose={handleClose}>
            <div className="clientes">
                <h1>Relatório de Clientes</h1>
                <ClientesTable />
            </div>
        </Layout>
    );
};

export default Clientes;