import React from 'react';
import './PagarMe.css';
import mpone from '../../../images/PagarMeTutorial/1.png';
import mptwo from '../../../images/PagarMeTutorial/2.png';
import mpthree from '../../../images/PagarMeTutorial/3.png';
import mpfour from '../../../images/PagarMeTutorial/4.png';

const PagarMe: React.FC = () => {
    return (
        <div className="help-page">
            <header className="help-header">
                <h1>Tutorial de Integração com o PagarMe</h1>
            </header>
            <main className="help-content">
                <p style={{ fontSize: '22px' }}><b>Acesse a sua conta do Marketplace.</b></p>
                <p style={{ fontSize: '18px' }}>1 - No menu lateral, selecione o opção <b>"Marketplace"</b>.</p>
                <p style={{ fontSize: '18px' }}>2 - No submenu que será aberto, selecione a opção <b>"Configurações"</b>.</p>
                <img src={mpone.toString()} alt="Imagem do tutorial" style={{ width: '1200px', height: '500px' }} />
                <p style={{ fontSize: '18px' }}>3 - Abrirá uma nova tela, copie a URL do seu <b>"Webhook PagarMe"</b>.</p>
                <img src={mptwo.toString()} alt="Imagem do tutorial" style={{ width: '1200px', height: '500px' }} />
                <p style={{ fontSize: '18px' }}>4 - Acesse sua conta no PagarMe, e no menu lateral clique em <b>"Configurações"</b>.</p>
                <p style={{ fontSize: '18px' }}>5 - No submenu que abrirá seleciona a opção <b>"Webhooks"</b>.</p>
                <p style={{ fontSize: '18px' }}>6 - Na nova tela que abrirá, clique no botão <b>"+ Criar webhook"</b>.</p>
                <img src={mpthree.toString()} alt="Imagem do tutorial" style={{ width: '1200px', height: '800px' }} />
                <p style={{ fontSize: '18px' }}>7 - Nesta tela você deverá preencher as informações conforme a imagem abaixo.</p>
                <p style={{ fontSize: '18px' }}>8 - Ative o toogle <b>"Status"</b>. 
                    <br/>- Cole a URL copiada anteriormente e a cole no campo <b>"URL"</b>. 
                    <br/>- Preencha 3 em <b>"Máximo de Tentativas"</b>. 
                    <br/>- Habilite a <b>"Autenticação"</b>. 
                    <br/>- Em usuario do Webhook, você irá preencher as suas credenciais de acesso ao <b>"Marketplace"</b>. 
                    <br/>- Em <b>"Eventos"</b> selecione <b>"Pedido"</b> e marque a caixa <b>"order.paid"</b>. 
                    <br/>- Insira sua senha do PagarMe abaixo e <b>Salve</b> a <b>Configuração</b>. 
                </p>
                <img src={mpfour.toString()} alt="Imagem do tutorial" style={{ width: '1200px', height: '800px' }} />
                <p style={{ fontSize: '22px' }}><b>Pronto! Você já pode realizar suas vendas pelo PagarMe!</b></p>
            </main>
            <footer className="help-footer">
                <p>Se você precisar de mais ajuda, entre em contato conosco.</p>
            </footer>
        </div>
    );
};

export default PagarMe;