// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/Configuracoes/Configuracoes.css */
.configuracoes-container {
    padding: 20px;
    margin-top: 30px;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Configuracoes/Configuracoes.css"],"names":[],"mappings":"AAAA,8CAA8C;AAC9C;IACI,aAAa;IACb,gBAAgB;EAClB","sourcesContent":["/* src/pages/Configuracoes/Configuracoes.css */\n.configuracoes-container {\n    padding: 20px;\n    margin-top: 30px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
