// src/components/Pixel/Pixel.tsx
import React, { useEffect, useState } from 'react';
import { Button, TextField, Box } from '@material-ui/core';
import './Pixel.css';
import api from '../../../services/api';
import { notifyError, notifySuccess } from '../../Commom/Notification/Notification';
import Modal from '../../Commom/Modal/Modal';

const MAX_PIXELS = 5;


const Pixel = ({ produto }: any) => {
  const [googlePixels, setGooglePixels] = useState<string[]>([]);
  const [facebookPixels, setFacebookPixels] = useState<string[]>([]);
  const [tiktokPixels, setTiktokPixels] = useState<string[]>([]);
  const usuario = JSON.parse(localStorage.getItem('usuario') || '') as { nome: string, id_marketplace: string };
  const [openDialog, setOpenDialog] = useState(false);
  // const [pixelToDelete, setPixelToDelete] = useState<{ index: number, setPixels: React.Dispatch<React.SetStateAction<string[]>> } | null>(null);
  const [pixelToDelete, setPixelToDelete] = useState<{ index: number, pixel: string, type: string, setPixels: React.Dispatch<React.SetStateAction<string[]>> } | null>(null);

  useEffect(() => {
    const fetchPixels = async () => {
      try {
        const response = await api.get(`/marketplace/listar-pixel/${usuario.id_marketplace}/produto/${produto.id}`) 
        if (response.status === 200) {
          const { pixel_google, pixel_facebook, pixel_tiktok } = response.data;

          // Parse the JSON strings into arrays
          const googlePixels = JSON.parse(pixel_google);
          const facebookPixels = JSON.parse(pixel_facebook);
          const tiktokPixels = JSON.parse(pixel_tiktok);

          // Se os arrays de pixels estiverem vazios, adicione um pixel
          if (!googlePixels.length) googlePixels.push('');
          if (!facebookPixels.length) facebookPixels.push('');
          if (!tiktokPixels.length) tiktokPixels.push('');

          setGooglePixels(googlePixels);
          setFacebookPixels(facebookPixels);
          setTiktokPixels(tiktokPixels);
        }
      } catch (error) {
        console.error('Erro ao listar os pixels', error);
      }
    };

    fetchPixels();
  }, []);

  const handleSavePixels = async () => {
    try {
      const response = await api.post('/marketplace/criar-pixel', {
        id_marketplace: usuario.id_marketplace,
        pixel_facebook: facebookPixels,
        pixel_google: googlePixels,
        pixel_tiktok: tiktokPixels,
        id_produto: produto.id,
        nome: usuario.nome
      });

      if (response.status === 200) {
        notifySuccess('Pixels salvos com sucesso');
      }
    } catch (error) {
      notifyError('Erro ao salvar os pixels');
      console.error('Erro ao salvar os pixels', error);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = (index: number, pixel: string, type: string, setPixels: React.Dispatch<React.SetStateAction<string[]>>) => {
    setPixelToDelete({ index, pixel, type, setPixels } as { index: number, pixel: string, type: string, setPixels: React.Dispatch<React.SetStateAction<string[]>> });
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (pixelToDelete) {

      try {
        const response = await api.post('/marketplace/deletar-pixel', {
          index: pixelToDelete.index,
          pixel: pixelToDelete?.pixel,
          type: pixelToDelete?.type,
          id_produto: produto.id,
          id_marketplace: usuario.id_marketplace,
          nome: usuario.nome
        });

        if (response.status === 200) {
          notifySuccess('Pixel excluído com sucesso');
          pixelToDelete?.setPixels(prevPixels => prevPixels.filter((_, i) => i !== pixelToDelete.index));
        }
      } catch (error) {
        notifyError('Erro ao excluir o pixel');
        console.error('Erro ao excluir o pixel', error);
      }
    }
  
    handleCloseDialog();
  };

  const handleAddPixel = (setPixels: React.Dispatch<React.SetStateAction<string[]>>) => () => {
    setPixels(prevPixels => [...prevPixels, '']);
  };

  const handleRemovePixel = (index: number, setPixels: React.Dispatch<React.SetStateAction<string[]>>) => () => {
    setPixels(prevPixels => prevPixels.filter((_, i) => i !== index));
  };

  const handlePixelChange = (index: number, setPixels: React.Dispatch<React.SetStateAction<string[]>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPixel = event.target.value;
    setPixels(prevPixels => prevPixels.map((pixel, i) => i === index ? newPixel : pixel));
  };

  return (
    <Box className="pixel-container">
      <Box className="platform-container">
        {['Google', 'Facebook', 'TikTok'].map((platform, i) => {
          const pixels = i === 0 ? googlePixels : i === 1 ? facebookPixels : tiktokPixels;
          const setPixels = i === 0 ? setGooglePixels : i === 1 ? setFacebookPixels : setTiktokPixels;
          return pixels && (
            <Box key={platform} className="pixel-platform">
              <h2>{platform} Pixels</h2>
              {pixels.map((pixel, i) => (
                <Box key={i} className="pixel-input">
                  <TextField value={pixel} onChange={handlePixelChange(i, setPixels)} label={`Pixel ${i + 1}`} />
                  <Button variant="contained" color="secondary" onClick={() => handleOpenDialog(i, pixel, platform, setPixels)}>Excluir</Button>
                </Box>
              ))}
              {pixels.length < MAX_PIXELS && <Button variant="contained" color="primary" onClick={handleAddPixel(setPixels)}>+</Button>}
            </Box>
          );
        })}
      </Box>
      <Box className="save-button-container">
        <Button variant="contained" color="primary" onClick={handleSavePixels}>Salvar</Button>
      </Box>
      <Modal open={openDialog} title="Excluir Pixel" onClose={handleCloseDialog}>
        <p>Você tem certeza de que deseja excluir este pixel?</p>
        <Button onClick={handleCloseDialog} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleConfirmDelete} color="primary" autoFocus>
          Excluir
        </Button>
      </Modal>
    </Box>
  );
};

export default Pixel;