// src/components/OfertaNova/OfertaNova.tsx
import React, { useContext, useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, TextField, Button, Box, OutlinedInput } from '@material-ui/core';
import './OfertaNova.css';
import api from '../../../services/api';
import { notifyError, notifySuccess } from '../../Commom/Notification/Notification';
import OfertasContext from '../../Context/OfertasContext';

interface OfertaNovaProps {
    voltar: () => void;
}

interface Plano {
    id: number;
    id_produto: number;
    descricao: string;
    dias: number;
    valor: string;
}

interface Produto {
    id: number;
    nome: string;
    descricao: string;
    status: boolean;
    foto: string;
    estrutura: string;
    id_usuario_created: number;
    id_pagarme: null | number;
    planos: Plano[];
}

const OfertaNova: React.FC<OfertaNovaProps> = ({ voltar }) => {
    const [produto, setProduto] = useState<Produto | null>(null);
    const [plano, setPlano] = useState<Plano | null>(null);
    const [metodoPagamento, setMetodoPagamento] = useState('');
    const [desconto, setDesconto] = useState(0);
    const [produtos, setProdutos] = useState<Produto[]>([]);
    const [planos, setPlanos] = useState<Plano[]>([]);
    const context = useContext(OfertasContext);

    if (!context) {
        throw new Error('Produtos deve estar dentro de um OfertasProvider');
    }

    const { ofertas, setOfertas } = context;

    const handleAddOferta = (novaOferta: any) => {
        // Atualize o estado do contexto com a nova oferta
        setOfertas([...ofertas, novaOferta]);
    };

    const criarOferta = () => {
        const usuario = JSON.parse(localStorage.getItem('usuario') || '') as { nome: string, id_marketplace: string };
        const oferta = {
            id_marketplace: usuario.id_marketplace,
            id_produto: produto?.id ?? '',
            nome_produto: produto?.nome,
            id_plano: plano?.id,
            nome_plano: plano?.descricao,
            valor: plano?.valor,
            metodo: metodoPagamento,
            desconto: desconto,
            nome: usuario.nome,
        };

        api.post(`/marketplace/nova-oferta`, oferta)
            .then(response => {
                const novaOferta = {
                    plano: plano?.descricao,
                    url_checkout: response.data.url_checkout,
                    valor: response.data.valor,
                    status: response.data.status,
                };
                notifySuccess('Oferta criada com sucesso!');
                handleAddOferta(novaOferta);
                voltar();
            })
            .catch(error => {
                notifyError('Erro ao criar oferta');
                console.error('Erro ao criar oferta:', error);
            });
    };

    useEffect(() => {
        api.get('/marketplace/produtos')
            .then(response => {
                setProdutos(response.data);
                setPlanos(response.data.map((produto: any) => produto.planos).flat());
            })
            .catch(error => {
                console.error('Erro ao buscar produtos e planos:', error);
            });
    }, []);

    useEffect(() => {
        console.log('plano', planos);
    }, [planos]);

    return (
        <Box className="oferta-nova-container">
            <FormControl fullWidth>
                <InputLabel>Produto</InputLabel>
                <Select value={produto?.nome} onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const produtoSelecionado = produtos.find(p => p.nome === event.target.value);
                    setProduto(produtoSelecionado || null);
                }}>
                    {produtos.map((produto) => (
                        <MenuItem key={produto.id} value={produto.nome}>{produto.nome}</MenuItem>
                    ))}
                </Select>
            </FormControl>
           <FormControl fullWidth>
                <InputLabel>Plano</InputLabel>
                <Select value={plano?.id} onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const planoSelecionado = planos.find(p => p.id === event.target.value);
                    setPlano(planoSelecionado || null);
                }}>
                    {planos.filter(p => p.id_produto === produto?.id).map((plano) => (
                        <MenuItem key={plano.id} value={plano.id}>{plano.descricao}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField fullWidth disabled placeholder="Valor" value={plano?.valor} />
             <FormControl variant="outlined" style={{ width: '100%', marginBottom: '20px' }}>
                <InputLabel id="metodo-pagamento-label" shrink={metodoPagamento ? true : false} htmlFor="metodo-pagamento-label">Métodos de Pagamento</InputLabel>
                <Select
                    label="Métodos de Pagamento"
                    value={metodoPagamento}
                    onChange={(event) => setMetodoPagamento(event.target.value as string)}
                    input={<OutlinedInput label="Métodos de Pagamento" id="metodo-pagamento-label" />}
                >
                    <MenuItem value='1'>PIX</MenuItem>
                    <MenuItem value='2'>CARTÃO</MenuItem>
                    <MenuItem value='3'>TODOS</MenuItem>
                </Select>
            </FormControl>
            <TextField fullWidth label="Desconto %" type='number' value={desconto} onChange={(event) => setDesconto(Number(event.target.value) || 0)} />
            <Box className="oferta-nova-buttons">
                <Button variant="contained" color="primary" onClick={criarOferta}>Criar</Button>
                <Button variant="contained" color='secondary' onClick={voltar}>Voltar</Button>
            </Box>
        </Box>
    );
};

export default OfertaNova;