import React, { useEffect, useRef, useState } from 'react';
import { Container, Typography, TextField, Button } from '@material-ui/core';
import logo from '../../../images/dezp-logo.png'; // Substitua pelo caminho do seu logo
import './ComprarCreditos.css';
import { Box } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import api from '../../../services/api';
import { notifySuccess } from '../../Commom/Notification/Notification';
import { FaCopy } from 'react-icons/fa';
import QRCode from 'qrcode.react';
import CircularProgress from '@material-ui/core/CircularProgress';

function ComprarCreditos() {
  const [creditos, setCreditos] = useState(10);
  const location = useLocation();
  const userData = location.state.userData;
  const navigate = useNavigate();
  const [paymentId, setPaymentId] = useState(null);
  const intervalRef = useRef<null | NodeJS.Timeout>(null);
  const [qrCodeURL, setQrCodeURL] = useState(null);
  const [pixCode, setPixCode] = useState(null);
  const [valor, setValor] = useState(0);
  const [idPagamento, setIdPagamento] = useState(null);
  const [isComprarCreditosLoading, setIsComprarCreditosLoading] = useState(false);

  useEffect(() => {
    if (paymentId) {
      intervalRef.current = setInterval(async () => {
        try {
          const response = await api.get(`/public/check-payment-register/${paymentId}`);
          if (response.data.status === 'PAID') {

            if (userData.id) {
              const updateCredits = await api.put(`/marketplace/${userData.id}/update-credits`, {
                creditos,
                valor,
                id_usuario: userData.id,
                id_transacao: paymentId,
                id_pagamento: idPagamento
              });

              if (updateCredits.status === 200) {
                notifySuccess('Créditos adicionados com sucesso!');
                navigate('/dashboard');
              }
            } else {
              const cadastroResponse = await api.post('/public/cadastro', {
                ...userData,
                creditos,
                id_transacao: paymentId,
                valor
              });

              if (cadastroResponse.status === 200) {
                notifySuccess('Cadastro efetuado com sucesso!');
                navigate('/');
              }
            }

            clearInterval(intervalRef.current!);
          }
        } catch (error) {
          
        }
      }, 5000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [paymentId]);

  const handleCreditosChange = (event: any) => {
    const valor = event.target.value;
    if (valor >= 10) {
      setCreditos(valor);
    }
  };

  const calcularValor = () => {
    return creditos * Number(process.env.REACT_APP_VALOR_CREDITO);
  };

  const comprarCreditos = async () => {
    setIsComprarCreditosLoading(true);

    try {
      let response;
      const calculaValor = calcularValor()
      setValor(calculaValor);
      if (userData.id) {
        // Se o usuário já está cadastrado, adicione créditos à conta existente
        response = await api.put(`/marketplace/${userData.id}/buy-credits`, {
          creditos: creditos,
          valor: calculaValor
        });

        setIdPagamento(response.data.id);
      } else {
      console.log('info', userData.email, creditos, calculaValor)
        response = await api.post('/public/cadastro/register-payment', {
          email: userData.email,
          creditos: creditos,
          valor: calculaValor
        });
      }


      setPaymentId(response.data.payment.id);
      setQrCodeURL(response.data.payment.point_of_interaction.transaction_data.qr_code);
      setPixCode(response.data.payment.point_of_interaction.transaction_data.qr_code);
    } catch (error) {
    
    }

    setIsComprarCreditosLoading(false);
  };

  return (
    <Container maxWidth="sm" className='comprar-creditos-container'>
      <div className='qr-code-container'>
        {qrCodeURL ? (
          <div>
            <QRCode value={qrCodeURL} size={300} className='qr-code' />
            <div className='pix-code-container'>
              <input value={pixCode || ''} disabled className='pix-code' />
              {pixCode && <FaCopy className='copy-icon' onClick={() => navigator.clipboard.writeText(pixCode)} />}
            </div>
            <p>NO APLICATIVO DO SEU BANCO <br /><b style={{ color: '#1000f1' }}>UTILIZE A OPÇÃO PIX LER QR CODE</b> ou <b style={{ color: '#1000f1' }}>PIX COPIA E COLA</b></p>
          </div>
        ) : (
          <div>
            <img src={logo.toString()} alt="Logo" />
            <Typography variant="h4" component="h1" gutterBottom align='center'>
              Adicionar Créditos
            </Typography>
            <TextField
              type="number"
              value={creditos}
              onChange={handleCreditosChange}
              label="Quantidade de Créditos"
              variant="outlined"
              fullWidth
              className='input-comprar-creditos'
            />
            <Typography variant="h6" component="h2" gutterBottom align='center'>
              Valor a pagar: ${calcularValor()}
            </Typography>
            <Box className='group-comprar-creditos'>
              <Button variant="contained" color="primary" fullWidth onClick={comprarCreditos} disabled={isComprarCreditosLoading}>
                {isComprarCreditosLoading ? <CircularProgress size={24} /> : 'Comprar'}
              </Button>
              <Link to={userData.id ? '/dashboard' : '/cadastro'}>
                <Button variant="contained" color="secondary" fullWidth>
                  Voltar
                </Button>
              </Link>
            </Box>
          </div>
        )}
      </div>
    </Container>
  );
}

export default ComprarCreditos;