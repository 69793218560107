// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/EquipeTable/EquipeTable.css */
.equipe-table-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
  }

  .equipe-table-buscar {
    width: 340px;
  }

  .option {
    display: block;
  }`, "",{"version":3,"sources":["webpack://./src/components/Marketplace/EquipeTable/EquipeTable.css"],"names":[],"mappings":"AAAA,+CAA+C;AAC/C;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,aAAa;EACf;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,cAAc;EAChB","sourcesContent":["/* src/components/EquipeTable/EquipeTable.css */\n.equipe-table-container {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    padding: 40px;\n  }\n\n  .equipe-table-buscar {\n    width: 340px;\n  }\n\n  .option {\n    display: block;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
