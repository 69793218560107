// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    text-align: center;
    background-color: #f8f9fa;
    color: #212529;
    padding: 20px;
}

.help-header, .help-footer {
    background-color: #6535a3;
    color: #fff;
    padding: 20px;
}

.help-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.help-content img {
    max-width: 100%;
    height: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/Tutorial/MercadoPago/MercadoPago.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB","sourcesContent":[".help-page {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    height: 100vh;\n    text-align: center;\n    background-color: #f8f9fa;\n    color: #212529;\n    padding: 20px;\n}\n\n.help-header, .help-footer {\n    background-color: #6535a3;\n    color: #fff;\n    padding: 20px;\n}\n\n.help-content {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.help-content img {\n    max-width: 100%;\n    height: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
