import React, { useState, useEffect } from 'react';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, Select } from '@material-ui/core';
import './EquipeTable.css';
import api from '../../../services/api';
import { Box, MenuItem, TablePagination } from '@mui/material';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'
import Modal from '../../Commom/Modal/Modal';
import { notifyCredentials, notifyError, notifySuccess } from '../../Commom/Notification/Notification';

interface Membro {
  id: number;
  nome: string;
  email: string;
  telefone: string;
  cpf: string;
  cargo: string;
  date_created: Date;
  last_login: Date;
  status: boolean;
}

const EquipeTable: React.FC = () => {
  const usuario = JSON.parse(localStorage.getItem('usuario') || '') as { nome: string, id_marketplace: string, cargo: string };
  const [membros, setMembros] = useState<Membro[]>([]);
  const [filtroNome, setFiltroNome] = useState('');
  const [filtroEmail, setFiltroEmail] = useState('');
  const [filtroCargo, setFiltroCargo] = useState('');
  const [filtroCadastro, setFiltroCadastro] = useState<Date | null>(null);
  const [filtroLogin, setFiltroLogin] = useState<Date | null>(null);
  const [filtroStatus, setFiltroStatus] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [newCPF, setNewCPF] = useState('');
  const [newNome, setNewNome] = useState('');
  const [newTelefone, setNewTelefone] = useState('');
  const [newCargo, setNewCargo] = useState('Selecione um cargo');
  const [selectedMember, setSelectedMember] = useState<Membro | null>(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  // Funções para abrir e fechar o modal de confirmação
  const handleOpenDeleteConfirmation = () => {
    setOpenDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setOpenDeleteConfirmation(false);
  };

  useEffect(() => {
    fetchMembros();
  }, [page, rowsPerPage]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApplyFilters = () => {
    fetchMembros();
    handleClose();
  };

  const resetFilters = () => {
    setFiltroNome('');
    setFiltroEmail('');
    setFiltroCargo('');
    setFiltroCadastro(null); 
    setFiltroLogin(null);
    setFiltroStatus('');
  };

  const handleAddMember = async () => {
    if (!newEmail || !newCargo || newCargo === 'Selecione um cargo' || !newCPF || !newTelefone) {
      notifyError('Por favor, preencha o email e o cargo.');
      return;
    }

    try {
     const memberRegister = await api.post(`/marketplace/cadastrar-membro/${usuario.id_marketplace}`, {
        email: newEmail,
        cargo: newCargo,
        nome: newNome,
        telefone: newTelefone,
        cpf: newCPF,
        nome_usuario: usuario.nome
      });

      notifySuccess('Membro cadastrado com sucesso.');
      notifyCredentials('Membro cadastrado com sucesso.', `Email: ${memberRegister.data.email}, Senha: ${memberRegister.data.password}`);
      setNewEmail('');
      setNewCargo('Selecione um cargo');
      setOpenAdd(false);
      fetchMembros();
    } catch (error) {
      console.error('Erro ao cadastrar membro', error);
      notifyError('Erro ao cadastrar membro.');
    }
  };

  const fetchMembros = async () => {
    try {
      const response = await api.get(`/marketplace/listar-equipe/${usuario.id_marketplace}`, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          nome: filtroNome,
          email: filtroEmail,
          cargo: filtroCargo,
          cadastro: filtroCadastro,
          login: filtroLogin,
          status: filtroStatus
        }
      });

      setMembros(response.data);
    } catch (error) {
      console.error('Erro ao buscar membros', error);
    }
  };

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleOpenEdit = (membro: Membro) => {
    setSelectedMember(membro);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setSelectedMember(null);
    setOpenEdit(false);
  };

  const handleEditMember = async () => {
    if (!selectedMember) {
      notifyError('Nenhum membro selecionado.');
      return;
    }

    try {
      await api.put(`/marketplace/editar-membro/${usuario.id_marketplace}`, { selectedMember, nome: usuario.nome });

      notifySuccess('Membro editado com sucesso.');
      handleCloseEdit();
      fetchMembros();
    } catch (error) {
      console.error('Erro ao editar membro', error);
      notifyError('Erro ao editar membro.');
    }
  };

  const handleDeleteMember = async () => {
    if (!selectedMember) {
      notifyError('Nenhum membro selecionado.');
      return;
    }

    try {
      await api.post(`/marketplace/excluir-membro/${usuario.id_marketplace}`, { email: selectedMember.email, nome: usuario.nome});

      notifySuccess('Membro excluído com sucesso.');
      handleCloseEdit();
      handleCloseDeleteConfirmation();
      fetchMembros();
    } catch (error) {
      console.error('Erro ao excluir membro', error);
      notifyError('Erro ao excluir membro.');
    }
  };

  const handleEditField = (field: keyof Membro, value: any) => {
    if (selectedMember) {
      setSelectedMember({ ...selectedMember, [field]: value });
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="equipe-table-container">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {(usuario.cargo === 'admin' || usuario.cargo === 'gerente') && (
            <Button variant="outlined" color="primary" onClick={handleOpenAdd} style={{ width: '120px', marginRight: '10px' }}>
              Adicionar
            </Button>
          )}
          <Button variant="outlined" color="primary" onClick={handleOpen} style={{ width: '120px' }}>
            Filtros
          </Button>
        </div>
        <Modal open={openAdd} title="Adicionar Membro" onClose={handleCloseAdd}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TextField label="Email" value={newEmail} onChange={(event) => setNewEmail(event.target.value)} style={{ marginBottom: '10px' }} />
            <TextField
              label="Nome"
              value={newNome}
              onChange={(e) => setNewNome(e.target.value)}
              style={{ marginBottom: '20px' }}
            />
            <TextField
              label="CPF"
              value={newCPF}
              onChange={(event) => setNewCPF(event.target.value)}
              style={{ marginBottom: '10px' }}
            />
            <TextField
              label="Telefone"
              value={newTelefone}
              onChange={(event) => setNewTelefone(event.target.value)}
              style={{ marginBottom: '10px' }}
            />
            <Select label="Cargo" value={newCargo} onChange={(event) => setNewCargo(event.target.value as string)} style={{ marginBottom: '10px', width: '100%' }}>
              <MenuItem className="option" value="Selecione um cargo">Selecione um cargo</MenuItem>
              <MenuItem className="option" value="gerente">Gerente</MenuItem>
              <MenuItem className="option" value="suporte">Suporte</MenuItem>
            </Select>
            <Button variant="contained" color="primary" onClick={handleAddMember} style={{ width: '120px' }}>
              Cadastrar
            </Button>
          </div>
        </Modal>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Filtros</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField label="Nome" value={filtroNome} onChange={(event) => setFiltroNome(event.target.value)} />
              <TextField label="Email" value={filtroEmail} onChange={(event) => setFiltroEmail(event.target.value)} />
              <TextField label="Cargo" value={filtroCargo} onChange={(event) => setFiltroCargo(event.target.value)} />
              <DatePicker
                label="Cadastro"
                value={filtroCadastro}
                onChange={(date) => setFiltroCadastro(date)}
                format="dd/MM/yyyy"
                clearable
              />
              <DatePicker
                label="Último Login"
                value={filtroLogin}
                onChange={(date) => setFiltroLogin(date)}
                format="dd/MM/yyyy"
                clearable
              />
              <TextField label="Status" value={filtroStatus} onChange={(event) => setFiltroStatus(event.target.value)} />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetFilters} color="primary">Limpar</Button>
            <Button onClick={handleApplyFilters} color="primary">Aplicar</Button>
          </DialogActions>
        </Dialog>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: '#f5f5f5' }}>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Cargo</TableCell>
                <TableCell>Cadastro</TableCell>
                <TableCell>Último Login</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {membros.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((membro, index) => (
                <TableRow key={index} onClick={() => handleOpenEdit(membro)} style={{ cursor: 'pointer' }}>
                  <TableCell>{membro.email}</TableCell>
                  <TableCell>{membro.cargo}</TableCell>
                  <TableCell>{new Date(membro.date_created).toLocaleString('pt-BR')}</TableCell>
                  <TableCell>{membro.last_login ? new Date(membro.last_login).toLocaleString('pt-BR') : '-'}</TableCell>
                  <TableCell style={{ color: membro.status ? 'green' : 'red' }}><b>{membro.status === true ? 'Ativo' : 'Inativo'}</b></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={membros.length}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
            labelRowsPerPage="Resultados por página"
          />
        </TableContainer>
        <Modal open={openEdit} title="Editar Membro" onClose={handleCloseEdit}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
              <Select
                label="Cargo"
                value={selectedMember?.cargo || ''}
                onChange={(e) => handleEditField('cargo', e.target.value)}
                style={{ width: '200px', marginRight: '10px' }}
              >
                <MenuItem value={'Selecione um cargo'}>Selecione um cargo</MenuItem>
                <MenuItem value={'Gerente'}>Gerente</MenuItem>
                <MenuItem value={'Suporte'}>Suporte</MenuItem>
              </Select>
              <Select
                value={selectedMember?.status === true ? 'Ativo' : 'Inativo'}
                onChange={(e) => handleEditField('status', e.target.value === 'Ativo')}
                style={{ width: '200px', marginLeft: '10px' }}
              >
                <MenuItem value={'Ativo'}>Ativo</MenuItem>
                <MenuItem value={'Inativo'}>Inativo</MenuItem>
              </Select>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button variant="contained" color="primary" onClick={handleEditMember} style={{ width: '120px', marginRight: '10px' }}>
                Salvar
              </Button>
              <Button variant="contained" color="secondary" onClick={handleOpenDeleteConfirmation} style={{ width: '120px' }}>
                Excluir
              </Button>
            </div>
          </div>
        </Modal>
        <Modal open={openDeleteConfirmation} title="Excluir Membro" onClose={handleCloseDeleteConfirmation}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p>Tem certeza de que deseja excluir este membro?</p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button variant="contained" color="secondary" onClick={handleDeleteMember} style={{ width: '120px', marginRight: '10px' }}>
                Excluir
              </Button>
              <Button variant="contained" onClick={handleCloseDeleteConfirmation} style={{ width: '120px' }}>
                Cancelar
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default EquipeTable;