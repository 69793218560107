import React from 'react';
import { useForm } from 'react-hook-form';
import './Cadastro.css';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { notifyError } from '../../Commom/Notification/Notification';
import InputMask from 'react-input-mask';

const Cadastro = () => {
    const { register, handleSubmit, getValues } = useForm();
    const navigate = useNavigate();

    const validateForm = () => {
        let isValid = true;
        let errors = [];

        const values = getValues();

        if (values.nome.length < 5) {
            isValid = false;
            errors.push('O nome deve ter pelo menos 5 caracteres');
        }

        if (!values.email.includes('@')) {
            isValid = false;
            errors.push('Email inválido');
        }

        if (values.cpf.length === 0) {
            isValid = false;
            errors.push('O CPF é obrigatório');
        }

        if (!/^\+55 \(\d{2}\) \d \d{4}-\d{4}$/.test(values.phone)) {
            isValid = false;
            errors.push('Telefone inválido');
        }

    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^(){}[\]=+-:;<>.,/|~_\\])[A-Za-z\d@$!#%*?&^(){}[\]=+-:;<>.,/|~_\\]{8,}$/.test(values.senha)) {
            isValid = false;
            errors.push('A senha deve ter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial');
        }

        if (values.senha !== values.confirmSenha) {
            isValid = false;
            errors.push('As senhas devem corresponder');
        }

        errors.forEach(error => notifyError(error));

        return isValid;
    };

    const onSubmit = (data: any) => {
        if (validateForm()) {
            navigate('/comprar/creditos', { state: { userData: data } });
        }
    };

    return (
        <div className="cadastro-container">
            <h1>Cadastro</h1>
            <form onSubmit={handleSubmit(onSubmit)} className="cadastro-form">
                <input {...register("nome")} className="cadastro-field" placeholder="Nome" />
                <input {...register("email")} type="email" className="cadastro-field" placeholder="Email" />
                <InputMask {...register("cpf")} mask="999.999.999-99" className="cadastro-field" placeholder="CPF" />
                <InputMask {...register("phone")} mask="+55 (99) 9 9999-9999" className="cadastro-field" placeholder="Telefone" />
                <input {...register("senha")} type="password" className="cadastro-field" placeholder="Senha" />
                <input {...register("confirmSenha")} type="password" className="cadastro-field" placeholder="Confirmar Senha" />
                <div className='cadastro-group-button'>
                    <Button type="submit" variant="contained" color="primary">Cadastrar</Button>
                    <Link to="/"><Button variant="contained" color="secondary">Voltar</Button></Link>
                </div>
            </form>
        </div>
    );
};

export default Cadastro;