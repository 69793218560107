// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Pixel/Pixel.css */
.pixel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  }
  
  .platform-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .pixel-platform {
    flex: 1 1;
    margin-right: 20px;
  }
  
  .pixel-input {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .pixel-input > button {
    margin-left: 10px;
  }

.save-button-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/Marketplace/Pixel/Pixel.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,WAAW;EACb;;EAEA;IACE,SAAO;IACP,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;EACnB;;AAEF;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":["/* src/components/Pixel/Pixel.css */\n.pixel-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  }\n  \n  .platform-container {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n  }\n\n  .pixel-platform {\n    flex: 1;\n    margin-right: 20px;\n  }\n  \n  .pixel-input {\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n  }\n  \n  .pixel-input > button {\n    margin-left: 10px;\n  }\n\n.save-button-container {\n  display: flex;\n  justify-content: center;\n  margin-top: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
