// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/ConfigPerfil/ConfigPerfil.css */
.config-perfil-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .telefone-cpf-container {
    display: flex;
    justify-content: space-between;
    width: 600px;
  }

  .config-perfil-nivel {
    width: 60px;
    height: 60px;
  }

.autenticacao-container {
    display: flex;
    align-items: center; 
    width: 600px;
    margin-top: 20px;
  }

  .reset-senha-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 300px;
    gap: 10px;
  }
  
  .avatar-container {
    position: relative;
    width: 150px;
    height: 150px;
  }
  
  .avatar-container .edit-icon {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .avatar-container .config-perfil-image {
    display: none;
  }

  .avatar-container  .config-perfil-img{
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }`, "",{"version":3,"sources":["webpack://./src/components/Marketplace/ConfigPerfil/ConfigPerfil.css"],"names":[],"mappings":"AAAA,iDAAiD;AACjD;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,UAAU;IACV,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,YAAY;EACd;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;AAEF;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,SAAS;EACX;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,SAAS;EACX;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,kBAAkB;EACpB","sourcesContent":["/* src/components/ConfigPerfil/ConfigPerfil.css */\n.config-perfil-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 60%;\n    margin-left: auto;\n    margin-right: auto;\n  }\n  \n  .telefone-cpf-container {\n    display: flex;\n    justify-content: space-between;\n    width: 600px;\n  }\n\n  .config-perfil-nivel {\n    width: 60px;\n    height: 60px;\n  }\n\n.autenticacao-container {\n    display: flex;\n    align-items: center; \n    width: 600px;\n    margin-top: 20px;\n  }\n\n  .reset-senha-container {\n    margin-top: 20px;\n    display: flex;\n    flex-direction: column;\n    width: 300px;\n    gap: 10px;\n  }\n  \n  .avatar-container {\n    position: relative;\n    width: 150px;\n    height: 150px;\n  }\n  \n  .avatar-container .edit-icon {\n    position: absolute;\n    right: 0;\n    bottom: 0;\n  }\n\n  .avatar-container .config-perfil-image {\n    display: none;\n  }\n\n  .avatar-container  .config-perfil-img{\n    width: 150px;\n    height: 150px;\n    border-radius: 50%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
