// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/ProdutosTable.css */
.produtos-table-container {
    padding: 20px;
    margin-top: 20px;
    width: 60%;
}

.produtos-table-container img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    vertical-align: middle;
}

.produtos-table-container .MuiTableCell-root {
    vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/components/Marketplace/ProdutosTable/ProdutosTable.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;IACI,aAAa;IACb,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":["/* src/components/ProdutosTable.css */\n.produtos-table-container {\n    padding: 20px;\n    margin-top: 20px;\n    width: 60%;\n}\n\n.produtos-table-container img {\n    width: 50px;\n    height: 50px;\n    margin-right: 10px;\n    vertical-align: middle;\n}\n\n.produtos-table-container .MuiTableCell-root {\n    vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
