// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comprar-creditos-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; 
  margin: 20vh auto;
}

.comprar-creditos-container img {
  width: 200px;
  height: 200px;
  margin: 0 auto; 
}

.comprar-creditos-container Button{
  width: 120px;
  margin: 5px;
}

.comprar-creditos-container .input-comprar-creditos {
width: 400px;
}

.qr-code {
  width: 300px;
  height: 300px;
}

.pix-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pix-code {
  width: 300px;
  height: 30px;
  margin-right: 10px;
}

.copy-icon {
  color: #1000f1;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Marketplace/ComprarCreditos/ComprarCreditos.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;AACA,YAAY;AACZ;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":[".comprar-creditos-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center; \n  margin: 20vh auto;\n}\n\n.comprar-creditos-container img {\n  width: 200px;\n  height: 200px;\n  margin: 0 auto; \n}\n\n.comprar-creditos-container Button{\n  width: 120px;\n  margin: 5px;\n}\n\n.comprar-creditos-container .input-comprar-creditos {\nwidth: 400px;\n}\n\n.qr-code {\n  width: 300px;\n  height: 300px;\n}\n\n.pix-code-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 20px;\n}\n\n.pix-code {\n  width: 300px;\n  height: 30px;\n  margin-right: 10px;\n}\n\n.copy-icon {\n  color: #1000f1;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
