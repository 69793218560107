// src/pages/Vendas/Vendas.tsx
import React, { useState } from 'react';
import './Historico.css';
import Layout from '../../components/Commom/Layout/Layout';
import HistoricoTable from '../../components/Marketplace/HistoricoTable/HistoricoTable';

const Historico = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleMenuClick = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Layout onMenuClick={handleMenuClick} credits={100} isOpen={isOpen} onClose={handleClose}>
            <div className="historico">
                <h1>Histórico de créditos</h1>
                <HistoricoTable />
            </div>
        </Layout>
    );
};

export default Historico;