// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/Vendas/Vendas.css */
.historico {
    margin-top: 60px;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Historico/Historico.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;IACI,gBAAgB;EAClB","sourcesContent":["/* src/pages/Vendas/Vendas.css */\n.historico {\n    margin-top: 60px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
