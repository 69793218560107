// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resetar-senha-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 50vh auto; /* Center the container vertically */
    text-align: center; /* Center the text horizontally */
}

.resetar-group-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.resetar-group-button Button{
    width: 120px;
  margin: 5px;
  /* background-color: #d4d4d4; */
}`, "",{"version":3,"sources":["webpack://./src/components/Marketplace/ResetarSenha/ResetarSenha.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;IACT,iBAAiB,EAAE,oCAAoC;IACvD,kBAAkB,EAAE,iCAAiC;AACzD;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;AACA;IACI,YAAY;EACd,WAAW;EACX,+BAA+B;AACjC","sourcesContent":[".resetar-senha-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 20px;\n    margin: 50vh auto; /* Center the container vertically */\n    text-align: center; /* Center the text horizontally */\n}\n\n.resetar-group-button {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-top: 20px;\n}\n.resetar-group-button Button{\n    width: 120px;\n  margin: 5px;\n  /* background-color: #d4d4d4; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
