// src/RoutesComponent.tsx
import { Routes, Route,  } from 'react-router-dom';
import Cadastro from '../components/Marketplace/Cadastro/Cadastro';
import ComprarCreditos from '../components/Marketplace/ComprarCreditos/ComprarCreditos';
import ResetarSenha from '../components/Marketplace/ResetarSenha/ResetarSenha';
import PrivateRoute from '../components/Commom/PrivateRoute/PrivateRoute';
import Produtos from '../pages/Produtos/Produtos';
import Logs from '../pages/Logs/Logs';
import ConfiguracoesMarketplace from '../pages/ConfiguracoesMarketplace/ConfiguracoesMarketplace';
import Vendas from '../pages/Vendas/Vendas';
import Clientes from '../pages/Clientes/Clientes';
import Equipe from '../pages/Equipe/Equipe';
import Historico from '../pages/Historico/Historico';
import Configuracoes from '../pages/Configuracoes/Configuracoes';
import Login from '../components/Marketplace/Login/Login';
import Dashboard from '../pages/Dashboard/Dashboard';
import MercadoPago from '../pages/Tutorial/MercadoPago/MercadoPago';
import PagarMe from '../pages/Tutorial/PagarMe/PagarMe';

function RoutesComponent() {

    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/tutorial/mercado-pago" element={<MercadoPago />} />
            <Route path="/tutorial/pagar-me" element={<PagarMe />} />
            <Route path="/cadastro" element={<Cadastro />} />
            <Route path="/comprar/creditos" element={<ComprarCreditos />} />
            <Route path="/esqueceu-senha" element={<ResetarSenha />} />
            <Route path="/dashboard" element={<PrivateRoute requiredRoles={['admin', 'gerente', 'suporte']}><Dashboard /></PrivateRoute>} />
            <Route path="/produtos" element={<PrivateRoute requiredRoles={['admin', 'gerente']}><Produtos /></PrivateRoute>} />
            <Route path="/logs" element={<PrivateRoute requiredRoles={['admin']}><Logs /></PrivateRoute>} />
            <Route path="/configuracoes-marketplace" element={<PrivateRoute requiredRoles={['admin']}><ConfiguracoesMarketplace /></PrivateRoute>} />
            <Route path="/vendas" element={<PrivateRoute requiredRoles={['admin', 'gerente', 'suporte']}><Vendas /></PrivateRoute>} />
            <Route path="/clientes" element={<PrivateRoute requiredRoles={['admin', 'gerente', 'suporte']}><Clientes /></PrivateRoute>} />
            <Route path="/equipe" element={<PrivateRoute requiredRoles={['admin', 'gerente']}><Equipe /></PrivateRoute>} />
            <Route path="/historico" element={<PrivateRoute requiredRoles={['admin', 'gerente']}><Historico /></PrivateRoute>} />
            <Route path="/configuracoes" element={<PrivateRoute requiredRoles={['admin', 'gerente', 'suporte']}><Configuracoes /></PrivateRoute>} />
        </Routes>
    );
}

export default RoutesComponent;