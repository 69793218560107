import React, { useState, useEffect } from 'react';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, Select } from '@material-ui/core';
import './LogsTable.css';
import api from '../../../services/api';
import { Box, MenuItem, TablePagination } from '@mui/material';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'


interface Log {
    usuario: string;
    evento: string;
    data_created: string;
    cliente: string;
}

const LogsTable: React.FC = () => {
    const [logs, setLogs] = useState<Log[]>([]);
    const [filtroData, setFiltroData] = useState<Date | null>(null);
    const [open, setOpen] = useState(false);
    const [filtroEvento, setFiltroEvento] = useState('Selecione um evento');
    const [filtroCliente, setFiltroCliente] = useState('');
    const [filtroUsuario, setFiltroUsuario] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const usuario = JSON.parse(localStorage.getItem('usuario') || '') as { id_marketplace: string };

    useEffect(() => {
        fetchLogs();
    }, [page, rowsPerPage]);

    const fetchLogs = async () => {
        try {
            const response = await api.get(`/marketplace/listar-logs/${usuario.id_marketplace}`, {
                params: {
                    page: page + 1,
                    limit: rowsPerPage,
                    evento: filtroEvento,
                    usuario: filtroUsuario,
                    data: filtroData,
                    cliente: filtroCliente
                }
            });

            setLogs(response.data);
        } catch (error) {
            console.error('Erro ao buscar logs', error);
        }
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleApplyFilters = () => {
        fetchLogs();
        handleClose();
    };

    const resetFilters = () => {
        setFiltroEvento('Selecione um evento');
        setFiltroUsuario('')
        setFiltroData(null);
        setFiltroCliente('');
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="logs-table-container">
                <Button variant="outlined" color="primary" onClick={handleOpen} style={{ float: 'right' }}>Filtros</Button>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Filtros</DialogTitle>
                    <DialogContent>
                        <Box display="flex" flexDirection="column" gap={2}>
                            <TextField label="Usuario" value={filtroUsuario} onChange={(event) => setFiltroUsuario(event.target.value)} />
                            <Select label="Evento" value={filtroEvento} onChange={(event) => setFiltroEvento(event.target.value as string)}>
                                <MenuItem value="Selecione um evento">Selecione um evento</MenuItem>
                                <MenuItem value="Adicionou">Adicionou</MenuItem>
                                <MenuItem value="Atualizou">Atualizou</MenuItem>
                                <MenuItem value="Excluiu">Excluiu</MenuItem>
                                <MenuItem value="Enviou">Enviou</MenuItem>
                                <MenuItem value="Acessou">Acessou</MenuItem>
                            </Select>
                            <DatePicker
                                label="Data"
                                value={filtroData}
                                onChange={(date) => setFiltroData(date)}
                                format="dd/MM/yyyy"
                                clearable
                            />
                            <TextField label="Cliente" value={filtroCliente} onChange={(event) => setFiltroCliente(event.target.value)} />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={resetFilters} color="primary">Redefinir</Button>
                        <Button onClick={handleApplyFilters} color="primary">Aplicar Filtros</Button>
                    </DialogActions>
                </Dialog>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead style={{ backgroundColor: '#f5f5f5' }}>
                            <TableRow>
                                <TableCell>Usuário</TableCell>
                                <TableCell>Evento</TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell>Cliente</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {logs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((log, index) => (
                                <TableRow key={index}>
                                    <TableCell>{log.usuario}</TableCell>
                                    <TableCell>{log.evento}</TableCell>
                                    <TableCell>{new Date(log.data_created).toLocaleString('pt-BR')}</TableCell>
                                    <TableCell>{log.cliente}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={logs.length}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                        labelRowsPerPage="Resultados por página"
                    />
                </TableContainer>
            </div>
        </MuiPickersUtilsProvider>
    );
};

export default LogsTable;