import React from 'react';
import './MercadoPago.css';
import mpone from '../../../images/MercadoPagoTutorial/1.png';
import mptwo from '../../../images/MercadoPagoTutorial/2.png';
import mpthree from '../../../images/MercadoPagoTutorial/3.png';
import mpfour from '../../../images/MercadoPagoTutorial/4.png';
import mpfive from '../../../images/MercadoPagoTutorial/5.png';

const MercadoPago: React.FC = () => {
    return (
        <div className="help-page">
            <header className="help-header">
                <h1>Tutorial de Integração com o MercadoPago</h1>
            </header>
            <main className="help-content">
                <p style={{ fontSize: '22px' }}><b>Acesse a sua conta do MercadoPago.</b></p>
                <p style={{ fontSize: '18px' }}>1 - No menu lateral, selecione o opção <b>"Seu negócio"</b>.</p>
                <p style={{ fontSize: '18px' }}>2 - No submenu que será aberto, selecione a opção <b>"Configurações"</b>.</p>
                <p style={{ fontSize: '18px' }}>3 - Abrirá uma nova tela, em Gestão e Administração clique em <b>"Credenciais"</b>.</p>
                <img src={mpone.toString()} alt="Imagem do tutorial" style={{ width: '1200px', height: '800px' }} />
                <p style={{ fontSize: '18px' }}>4 - Na nova tela que irá abrir clique em <b>"Credenciais de Produção"</b>.</p>
                <img src={mptwo.toString()} alt="Imagem do tutorial" style={{ width: '1200px', height: '800px' }} />
                <p style={{ fontSize: '18px' }}>5 - Nessa nova tela você irá copiar o <b>"Access Token"</b>.</p>
                <img src={mpthree.toString()} alt="Imagem do tutorial" style={{ width: '1200px', height: '800px' }} />
                <p style={{ fontSize: '18px' }}>6 - Agora volte para o Marketplace e no meu latural clique em <b>"Marketplace"</b>.</p>
                <p style={{ fontSize: '18px' }}>7 - No submenu selecione a opção de <b>"Configurações"</b>.</p>
                <img src={mpfour.toString()} alt="Imagem do tutorial" style={{ width: '1200px', height: '500px' }} />
                <p style={{ fontSize: '18px' }}>8 - Nessa tela cole o <strong>"Access Token"</strong> copiado anteriormente no campo <strong>Mercado Pago</strong>.</p>
                <p style={{ fontSize: '18px' }}>9 - Clique em <b>"Salvar"</b>.</p>
                <img src={mpfive.toString()} alt="Imagem do tutorial" style={{ width: '1200px', height: '500px' }} />

                <p style={{ fontSize: '22px' }}><b>Pronto! Você já pode realizar suas vendas pelo MercadoPago!</b></p>
            </main>
            <footer className="help-footer">
                <p>Se você precisar de mais ajuda, entre em contato conosco.</p>
            </footer>
        </div>
    );
};

export default MercadoPago;