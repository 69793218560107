// src/components/Summary.tsx
import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { AttachMoney as MoneyIcon, ShoppingBasket as ShoppingIcon, Store as StoreIcon, PersonAdd as PersonAddIcon } from '@mui/icons-material';
import './Summary.css';

interface SummaryProps {
  faturamento: number;
  vendas: number;
  produtos: number;
  clientesNovos: number;
}

const Summary: React.FC<SummaryProps> = ({ faturamento, vendas, produtos, clientesNovos }) => {
  return (
    <Box className="summary" display="flex" justifyContent="space-between" >
      <Card className="summary-card" style={{ backgroundColor: '#f5f5f5'}}>
        <CardContent>
          <MoneyIcon style={{ color: '#0070ec' }}/>
          <Typography variant="h5" component="div">Faturamento</Typography>
          <Typography variant="body2">R$ {faturamento}</Typography>
        </CardContent>
      </Card>
      <Card className="summary-card" style={{ backgroundColor: '#f5f5f5'}}>
        <CardContent>
          <ShoppingIcon style={{ color: '#0070ec' }} />
          <Typography variant="h5" component="div">Vendas</Typography>
          <Typography variant="body2">{vendas}</Typography>
        </CardContent>
      </Card>
      <Card className="summary-card" style={{ backgroundColor: '#f5f5f5'}}>
        <CardContent>
          <StoreIcon style={{ color: '#0070ec' }}/>
          <Typography variant="h5" component="div">Produtos</Typography>
          <Typography variant="body2">{produtos}</Typography>
        </CardContent>
      </Card>
      <Card className="summary-card" style={{ backgroundColor: '#f5f5f5'}}>
        <CardContent>
          <PersonAddIcon style={{ color: '#0070ec' }}/>
          <Typography variant="h5" component="div">Clientes Novos</Typography>
          <Typography variant="body2">{clientesNovos}</Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Summary;