// src/pages/Vendas/Vendas.tsx
import React, { useState } from 'react';
import './Vendas.css';
import Layout from '../../components/Commom/Layout/Layout';
import VendasTable from '../../components/Marketplace/VendasTable/VendasTable';

const Vendas = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleMenuClick = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Layout onMenuClick={handleMenuClick} credits={100} isOpen={isOpen} onClose={handleClose}>
            <div className="vendas">
                <h1>Relatório de Vendas</h1>
                <VendasTable />
            </div>
        </Layout>
    );
};

export default Vendas;