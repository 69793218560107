// OfertaEditar.tsx
import React, { useContext, useState, useEffect } from 'react';
import OfertasContext from '../../Context/OfertasContext';
import { Select, MenuItem, Button, Typography, Input, IconButton } from '@material-ui/core';
import { FiCopy, FiTrash2, FiPlay, FiPause } from 'react-icons/fi';
import './OfertaEditar.css';
import Modal from '../../Commom/Modal/Modal';
import api from '../../../services/api';
import { notifyError, notifySuccess } from '../../Commom/Notification/Notification';
import { FormControl, InputLabel } from '@mui/material';

interface OfertaProps {
    id: number;
    plano: string;
    nomeProduto: string;
    url_checkout: string;
    valor: number;
    status: boolean;
    desconto: number;
    metodo: number;
}

const OfertaEditar = ({ ofertaInfo, onVoltar }: any) => {
    const [oferta, setOferta] = useState<OfertaProps | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [desconto, setDesconto] = useState(0);
    const context = useContext(OfertasContext);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const usuario = JSON.parse(localStorage.getItem('usuario') || '') as { nome: string, id_marketplace: string };
    const [metodoSelecionado, setMetodoSelecionado] = useState(ofertaInfo.metodo);

    if (!context) {
        throw new Error('Produtos deve estar dentro de um OfertasProvider');
    }

    const { ofertas, setOfertas } = context;

    useEffect(() => {
        const ofertaEncontrada = ofertas.find(o => o.id === ofertaInfo.id);
        setOferta(ofertaEncontrada);
    }, [ofertas, ofertaInfo]);

    if (!oferta) {
        return <div>Oferta não encontrada</div>;
    }

    const handlePlayPauseClick = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    // Função para fechar o modal de confirmação de exclusão
    const handleCloseDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    // Função para confirmar a exclusão
    const handleConfirmDelete = async () => {
        try {
            // Faz a chamada para a API para excluir a oferta
            const response = await api.post('/marketplace/deletar-oferta', {
                id: oferta.id,
                nome: usuario.nome
            });

            if (response.status === 200) {
                // Remova a oferta do estado localmente
                const updatedOfertas = ofertas.filter(o => o.id !== oferta.id);
                setOfertas(updatedOfertas);

                notifySuccess('Oferta excluída com sucesso');

                // Volta para a lista de ofertas
                onVoltar();
            }
        } catch (error) {
            notifyError('Erro ao excluir a oferta');
            console.error('Erro ao excluir a oferta', error);
        }

        // Feche o modal
        handleCloseDeleteModal();
    };

    const handleConfirmClick = async () => {
        try {
            const response = await api.post('/marketplace/status-oferta', {
                id: oferta.id,
                status: !oferta.status,
                nome: usuario.nome
            });

            if (response.status === 200) {
                // Atualize o estado da oferta localmente
                setOferta({
                    ...oferta,
                    status: !oferta.status,
                });

                const offerUpdate = {
                    id: oferta.id,
                    plano: oferta.plano,
                    url_checkout: response.data.url_checkout,
                    valor: response.data.valor,
                    status: response.data.status,
                    nomeProduto: oferta.nomeProduto,
                    desconto: oferta.desconto,
                };

                // Encontre o índice da oferta que você deseja atualizar
                const index = ofertas.findIndex(o => o.id === oferta.id);

                // Crie uma cópia do array de ofertas
                const updatedOfertas = [...ofertas];

                // Substitua a oferta antiga pela nova oferta
                updatedOfertas[index] = offerUpdate;

                // Atualize o estado com o novo array de ofertas
                setOfertas(updatedOfertas);

                notifySuccess(`Oferta ${oferta.status === false ? 'iniciada' : 'pausada'} com sucesso`);

                onVoltar()
            }
        } catch (error) {
            notifyError('Erro ao atualizar o status da oferta');
            console.error('Erro ao atualizar o status da oferta', error);
        }

        handleCloseModal();
    };

    const handleSaveClick = async () => {
        try {
            const response = await api.post('/marketplace/desconto-oferta', {
                id: oferta.id,
                nome: usuario.nome,
                novoDesconto: desconto === 0 ? oferta.desconto : desconto,
                metodoPagamento: metodoSelecionado
            });

            if (response.status === 200) {
                notifySuccess('Atualização realizada com sucesso');
                onVoltar();
            }
        } catch (error) {
            notifyError('Erro ao aplicar o desconto');
            console.error('Erro ao aplicar o desconto', error);
        }
    };

    const metodosPagamento: { [key: number]: string } = {
        1: 'PIX',
        2: 'CARTÃO',
        3: 'TODOS',
    };

    return (
        <div className="oferta-editar">
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '20px' }}>
                <Typography variant="h4" component="h2">
                    Detalhes da Oferta
                </Typography>
                <div>
                    <IconButton >
                        <FiTrash2 color='red' onClick={() => setDeleteModalOpen(true)} />
                    </IconButton>
                    <IconButton onClick={handlePlayPauseClick}>
                        {oferta.status === true ? <FiPause color='blue' /> : <FiPlay color='green' />}
                    </IconButton>
                </div>
            </div>
            <Select
                defaultValue={oferta.nomeProduto}
                style={{ width: '100%', marginBottom: '20px' }}
                disabled
            >
                <MenuItem value={oferta.nomeProduto}>{oferta.nomeProduto}</MenuItem>
            </Select>
            <Select
                defaultValue={oferta.plano}
                style={{ width: '100%', marginBottom: '20px' }}
                disabled
            >
                <MenuItem value={oferta.plano}>{oferta.plano}</MenuItem>
            </Select>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: '20px' }}>
                <Input
                    defaultValue={oferta.url_checkout}
                    inputProps={{ 'aria-label': 'URL do Checkout' }}
                    style={{ width: '90%' }}
                    disabled
                />
                <Button onClick={() => navigator.clipboard.writeText(oferta.url_checkout)} style={{ marginLeft: '10px' }}>
                    <FiCopy />
                </Button>
            </div>
            <Input
                defaultValue={oferta.valor}
                inputProps={{ 'aria-label': 'Valor Original' }}
                style={{ width: '100%', marginBottom: '20px' }}
                disabled
            />
            <Input
                type='number'
                defaultValue={oferta.desconto}
                inputProps={{ 'aria-label': 'Desconto' }}
                style={{ width: '100%', marginBottom: '20px' }}
                onChange={(event) => setDesconto(Number(event.target.value))}
            />
            <FormControl variant="outlined" style={{ width: '100%', marginBottom: '20px' }}>
                <InputLabel id="metodo-pagamento-label" shrink>
                    Métodos de Pagamento
                </InputLabel>
                <Select
                    labelId="metodo-pagamento-label"
                    value={metodoSelecionado}
                    onChange={(event) => setMetodoSelecionado(event.target.value as string)}
                    label="Métodos de Pagamento"
                >
                    <MenuItem value='1'>PIX</MenuItem>
                    <MenuItem value='2'>CARTÃO</MenuItem>
                    <MenuItem value='3'>TODOS</MenuItem>
                </Select>
            </FormControl>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '20px' }}>
                <Button variant="contained" color="primary" style={{ marginRight: '10px' }} onClick={handleSaveClick}>
                    Salvar
                </Button>
                <Button variant="contained" color="secondary" onClick={onVoltar}>
                    Voltar
                </Button>
            </div>

            <Modal open={modalOpen} title="Confirmação" onClose={handleCloseModal}>
                <p style={{ fontSize: '18px', textAlign: 'center' }}>
                    Você realmente deseja {oferta.status ? 'pausar' : 'iniciar'} esta oferta?
                </p>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                    <Button variant="contained" color="primary" onClick={handleConfirmClick}>
                        Sim
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleCloseModal}>
                        Não
                    </Button>
                </div>
            </Modal>

            <Modal open={deleteModalOpen} title="Confirmação de exclusão" onClose={handleCloseDeleteModal}>
                <p style={{ fontSize: '18px', textAlign: 'center' }}>
                    Você realmente deseja excluir esta oferta?
                </p>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                    <Button variant="contained" color="primary" onClick={handleConfirmDelete}>
                        Sim
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleCloseDeleteModal}>
                        Não
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default OfertaEditar;