import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa'

interface PrivateRouteProps {
    requiredRoles: string[];
    children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, requiredRoles }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        const role = JSON.parse(localStorage.getItem('usuario') || '') as { cargo: string };

        if (token && requiredRoles.includes(role.cargo)) {
            setIsAuthenticated(true);
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        function handleLogout() {
            setIsAuthenticated(false);
        }

        window.addEventListener('logout', handleLogout);

        return () => {
            window.removeEventListener('logout', handleLogout);
        };
    }, []);

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <FaSpinner size={50} />
            </div>
        );
    }

    if (!isAuthenticated) {
        navigate('/');
        return null;
    }

    return <>{children}</>;
};

export default PrivateRoute;