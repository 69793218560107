// src/components/WelcomeModal.tsx
import React, { useState } from 'react';
import './WelcomeModal.css';
import Modal from '../Modal/Modal';
import welcome from '../../../images/BoasVindas/welcome.png';
import twofa from '../../../images/BoasVindas/twofa.png';
import settings from '../../../images/BoasVindas/settings.png';
import ReactMarkdown from 'react-markdown'; // Adicione esta linha
import { Button } from '@material-ui/core';

interface WelcomeStep {
    title: string;
    image: string;
    description: string;
}

const steps: WelcomeStep[] = [
    { 
        title: 'Olá, seja bem-vindo!', 
        image: `${welcome.toString()}`, 
        description: `Fico muito feliz em ter você aqui. \n\nPara uma **melhor experiência** aqui vai algumas dicas...` 
    },
    { 
        title: 'Ativar autenticação dois fatores', 
        image: `${twofa.toString()}`, 
        description: 'Ative a autenticação de dois fatores para **aumentar a segurança** da sua conta. \n\nNós não nos responsabilizamos em casos de perda de conta.' 
    },
    { 
        title: 'Configure a sua conta', 
        image: `${settings.toString()}`, 
        description: 'Configure sua conta do Mercado Pago. \n\nClique no menu lateral e vá até **Marketplace > Configurações**. \n\nBoas Vendas! 😊' 
    },
];

const WelcomeModal: React.FC = () => {
    const [open, setOpen] = useState(true);
    const [currentStep, setCurrentStep] = useState(0);

    const handleClose = () => {
        setOpen(false);
    };

    const handleNext = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    return (
        <Modal open={open} title={steps[currentStep].title} onClose={handleClose}>
            <img src={steps[currentStep].image} alt={steps[currentStep].title} className="welcome-image" />
            <ReactMarkdown >{steps[currentStep].description}</ReactMarkdown> {/* Altere esta linha */}
            {currentStep < steps.length - 1 ? (
                <Button variant='contained' color='primary' onClick={handleNext}>Próximo</Button>
            ) : (
                <Button variant='contained' color='secondary' onClick={handleClose}>Fechar</Button >
            )}
        </Modal>
    );
};

export default WelcomeModal;