// src/components/PieChart.tsx
import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import './PieChart.css';

interface PieChartProps {
  data: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
    }[];
  };
  title: string;
}

Chart.register(...registerables);

const PieChart: React.FC<PieChartProps> = ({ data, title }) => {
  return (
    <div className='pie-chart-container'>
      <h3>{title}</h3>
      <Pie data={data} />
    </div>
  );
};

export default PieChart;