// src/components/OfertaTable.tsx
import React, { useContext, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import './OfertaTable.css';
import OfertasContext from '../../Context/OfertasContext';
import OfertaEditar from '../OfertaEditar/OfertaEditar';

interface Oferta {
  id: number;
  plano: string;
  url_checkout: string;
  valor: number;
  status: string;
}

interface OfertaTableProps {
  ofertas: Oferta[];
  id_produto: number;
}

const OfertaTable: React.FC<OfertaTableProps> = ({ id_produto }) => {
  const { ofertas } = useContext(OfertasContext) || {};
  const [localOfertas, setLocalOfertas] = useState(ofertas);
  const [selectedOferta, setSelectedOferta] = useState<Oferta | null>(null);

  useEffect(() => {
    setLocalOfertas(ofertas && ofertas.filter(oferta => oferta.id_produto === id_produto)); // Filtrar ofertas aqui
  }, [ofertas, id_produto]);

  const handleRowClick = (oferta: Oferta) => {
    setSelectedOferta(oferta);
  };

  const handleVoltar = () => {
    setSelectedOferta(null);
};

  return (
    <div>
      {selectedOferta ? (
        <OfertaEditar ofertaInfo={selectedOferta} onVoltar={handleVoltar} />
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                <TableCell>Plano</TableCell>
                <TableCell>Checkout</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {localOfertas?.map((oferta) => (
                <TableRow key={oferta.id} onClick={() => handleRowClick(oferta)}>
                  <TableCell>{oferta.plano}</TableCell>
                  <TableCell>{oferta.url_checkout}</TableCell>
                  <TableCell>{oferta.valor}</TableCell>
                  <TableCell style={{ color: oferta.status ? 'green' : 'red' }}>
                    {oferta.status ? 'Ativa' : 'Pausada'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default OfertaTable;