// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Header.css */

.header {
    /* background-color: #f0ad32; */
    background-color: #f5f5f5;
    color: #000000;
    z-index: 1400; 
    position: absolute;
  }
  
  .header .MuiToolbar-root {
    justify-content: space-between;
  }
  
  .header .MuiIconButton-root {
    margin: 0 5px;
  }

.header img {
  width: 180px;
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/Commom/Header/Header.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;IACI,+BAA+B;IAC/B,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,aAAa;EACf;;AAEF;EACE,YAAY;EACZ,YAAY;AACd","sourcesContent":["/* src/components/Header.css */\n\n.header {\n    /* background-color: #f0ad32; */\n    background-color: #f5f5f5;\n    color: #000000;\n    z-index: 1400; \n    position: absolute;\n  }\n  \n  .header .MuiToolbar-root {\n    justify-content: space-between;\n  }\n  \n  .header .MuiIconButton-root {\n    margin: 0 5px;\n  }\n\n.header img {\n  width: 180px;\n  height: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
