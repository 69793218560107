// src/components/ProdutosTable.tsx
import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@material-ui/core';
import './ProdutosTable.css';
import ProdutoDetalhes from '../ProdutoDetalhes/ProdutoDetalhes';
import logo from '../../../images/dezp-logo.png';

interface Produto {
  id: number;
  nome: string;
  descricao: string;
  foto: string;
  exclusive: boolean;
}

interface Plano {
  id_produto: number;
  descricao: string;
  valor: number;
}

interface Oferta {
  id_produto: number;
  plano: string;
  url_checkout: string;
  valor: number;
  status: string;
}

interface ProdutosProps {
  produtos: Produto[];
  planos: Plano[];
  ofertas: Oferta[];
}

const ProdutosTable: React.FC<ProdutosProps> = ({ produtos, planos, ofertas }) => {
  const [selectedProduto, setSelectedProduto] = useState<Produto | null>(null);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className="produtos-table-container" aria-label="simple table">
          <TableBody>
            <TableRow style={{ backgroundColor: '#f5f5f5' }}>
              <TableCell><strong>Nome</strong></TableCell>
              <TableCell><strong>Descrição</strong></TableCell>
            </TableRow>
            {produtos.map((produto, index) => {
              let usuario: { exclusive?: boolean } = {};
              try {
                usuario = JSON.parse(localStorage.getItem('cfg') || '{}');
              } catch (e) {
                console.error(e);
              }
              if (produto.exclusive && !usuario.exclusive) {
              console.log('nao exibe')
                return null; // Não renderiza o produto se for exclusivo e o usuário não tiver acesso exclusivo
              }
              return (
                <TableRow key={index} onClick={() => setSelectedProduto(produto)}>
                  <TableCell component="th" scope="row">
                    <img src={produto.foto} className="logo" />
                    {produto.nome}
                  </TableCell>
                  <TableCell>{produto.descricao}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedProduto &&
        <ProdutoDetalhes
          produto={selectedProduto}
          planos={planos.filter(plano => plano.id_produto === selectedProduto.id)}
          ofertas={ofertas.filter(oferta => oferta.id_produto === selectedProduto.id)}
        />
      }
    </div>
  );
};

export default ProdutosTable;