// src/pages/Vendas/Vendas.tsx
import React, { useState } from 'react';
import './Logs.css';
import Layout from '../../components/Commom/Layout/Layout';
import LogsTable from '../../components/Marketplace/LogsTable/LogsTable';

const Logs = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleMenuClick = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Layout onMenuClick={handleMenuClick} credits={100} isOpen={isOpen} onClose={handleClose}>
            <div className="logs">
                <h1>Logs Marketplace</h1>

                <LogsTable/>
            </div>
        </Layout>
    );
};

export default Logs;