// src/components/ConfigPerfil/ConfigPerfil.tsx
import React, { useEffect, useState } from 'react';
import { Avatar, Button, TextField, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import FileCopy from '@material-ui/icons/FileCopy';
import './ConfigPerfil.css';
import api from '../../../services/api';
import { notifyError, notifySuccess } from '../../Commom/Notification/Notification';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';

const ConfigPerfil: React.FC = () => {
    const usuario = JSON.parse(localStorage.getItem('usuario') || '') as { id_marketplace: string, cargo: string, id: number };
    const [nome, setNome] = useState('Nome do Usuário');
    const [email, setEmail] = useState('email@exemplo.com');
    const [telefone, setTelefone] = useState('(11) 12345-6789');
    const [cpf, setCpf] = useState('123.456.789-00');
    const [dataCadastro, setDataCadastro] = useState('01/01/2021');
    const [senhaAntiga, setSenhaAntiga] = useState('');
    const [novaSenha, setNovaSenha] = useState('');
    const [foto, setFoto] = useState('');
    const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
    const [confirmacaoSenha, setConfirmacaoSenha] = useState('');
    const [twoFactorData, setTwoFactorData] = useState<{ qrCodeImage: string, secret: string } | null>(null);
    const [isTwoFactorModalOpen, setIsTwoFactorModalOpen] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const navigate = useNavigate();
    const sanitizedImage = twoFactorData ? DOMPurify.sanitize(twoFactorData.qrCodeImage) : '';

    // Atualize a função handleImageUpload para armazenar o arquivo selecionado
    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setFile(event.target.files[0]);
        }
    };

    // Crie uma nova função para lidar com o envio do arquivo
    const handleSave = async () => {
        if (!file) {
            notifyError('Por favor, selecione uma foto.');
            return;
        }

        const formData = new FormData();
        formData.append('photo', file);

        try {
            await api.post(`/usuario/${usuario.id}/${usuario.id_marketplace}/photo`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            notifySuccess('Foto enviada com sucesso');
        } catch (error) {
            notifyError('Erro ao enviar foto');
            console.error('Erro ao enviar foto', error);
        }
    };

    const handleEnableTwoFactor = async () => {
        try {
            const response = await api.get(`/marketplace/configuracoes/enable-two-factor/${usuario.id_marketplace}/usuario/${usuario.id}`);
            const twoFactorData = response.data;

            setTwoFactorData(twoFactorData);
            setIsTwoFactorModalOpen(true);
            setTwoFactorEnabled(true);
        } catch (error) {
            console.error('Erro ao ativar autenticação 2F', error);
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await api.get(`/marketplace/configuracoes/info/${usuario.id_marketplace}/usuario/${usuario.id}`);
                const userData = response.data[0];

                setNome(userData.nome);
                setEmail(userData.email);
                setTelefone(userData.telefone);
                setCpf(userData.cpf);
                setDataCadastro(userData.date_created);
                setTwoFactorEnabled(userData.twoFactorEnabled)
                setFoto(userData.marketplace.foto);
            } catch (error) {
                console.error('Erro ao buscar dados do usuário', error);
            }
        };

        fetchUserData();
    }, []);

    const handleDisableTwoFactor = async () => {
        try {
            await api.post(`/marketplace/configuracoes/disable-two-factor/${usuario.id_marketplace}/usuario/${usuario.id}`);
            setTwoFactorEnabled(false);
        } catch (error) {
            console.error('Erro ao desativar autenticação 2F', error);
        }
    };

    const handleResetPassword = async () => {
        if (!senhaAntiga || !novaSenha || !confirmacaoSenha) {
            notifyError('Por favor, preencha todos os campos.');
            return;
        }

        if (novaSenha !== confirmacaoSenha) {
            notifyError('A nova senha e a confirmação de senha devem ser iguais.');
            return;
        }

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!passwordRegex.test(novaSenha)) {
            notifyError('A nova senha deve conter pelo menos uma letra minúscula, uma letra maiúscula, um número e um caractere especial.');
            return;
        }

        try {
            await api.post(`/marketplace/configuracoes/alterar-senha/${usuario.id_marketplace}/usuario/${usuario.id}`, {
                oldPassword: senhaAntiga,
                newPassword: novaSenha,
                confirmPassword: confirmacaoSenha,
            });

            notifySuccess('Senha alterada com sucesso. Faça login novamente!');
            setSenhaAntiga('');
            setNovaSenha('');
            setConfirmacaoSenha('');
            navigate('/login');
        } catch (error) {
            notifyError('Erro ao alterar senha');
            console.error('Erro ao alterar senha', error);
        }
    };

    return (
        <div className="config-perfil-container">
            <div className="avatar-container">
                <input accept="image/*" id="icon-button-file" type="file" className='config-perfil-image' onChange={handleImageUpload} />
                <Avatar alt={nome} src={foto} className='config-perfil-img' />
                {usuario.cargo === 'Admin' &&
                    <label htmlFor="icon-button-file">
                        <IconButton color="primary" component="span" className="edit-icon">
                            <EditIcon />
                        </IconButton>
                    </label>
                }
            </div>
            <h2>{nome}</h2>
            <p>{email}</p>
            <div className="telefone-cpf-container">
                <div>
                    <h3>Telefone</h3>
                    <p>{telefone}</p>
                </div>
                <div>
                    <h3>CPF</h3>
                    <p>{cpf}</p>
                </div>
            </div>
            <p>Data de Cadastro: {new Date(dataCadastro).toLocaleString('pt-BR')}</p>
            <div className="autenticacao-container" style={{ display: 'flex', justifyContent: 'center' }}>
                {twoFactorEnabled ? (
                    <Button variant="contained" color="secondary" size="small" onClick={handleDisableTwoFactor}>Desativar Autenticação 2F</Button>
                ) : (
                    <Button variant="contained" color="primary" size="small" onClick={handleEnableTwoFactor}>Ativar Autenticação 2F</Button>
                )}
            </div>
            <div className="reset-senha-container">
                <TextField label="Senha Antiga" type="password" value={senhaAntiga} onChange={(event) => setSenhaAntiga(event.target.value)} />
                <TextField label="Nova Senha" type="password" value={novaSenha} onChange={(event) => setNovaSenha(event.target.value)} />
                <TextField label="Confirmação de Senha" type="password" value={confirmacaoSenha} onChange={(event) => setConfirmacaoSenha(event.target.value)} />
                <Button variant="contained" color="primary" size="small" onClick={handleResetPassword}>Redefinir</Button>
            </div>
            { usuario.cargo === 'Admin' && <Button variant="contained" color="primary" style={{ marginTop: '20px' }} onClick={handleSave}>Salvar</Button> }
            <Dialog open={isTwoFactorModalOpen} onClose={() => setIsTwoFactorModalOpen(false)}>
                <DialogTitle>Faça o cadastro através do Google Authenticator</DialogTitle>
                <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src={twoFactorData?.qrCodeImage} alt="QR Code" />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p>{sanitizedImage}</p>
                        <IconButton onClick={() => navigator.clipboard.writeText(String(twoFactorData?.secret))}>
                            <FileCopy />
                        </IconButton>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsTwoFactorModalOpen(false)} color="primary">Fechar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ConfigPerfil;