// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard{
  margin-top: 64px;
}

.dashboard-modal {
  height: 550px;
}

.dashboard-modal p {
  margin-bottom: 10px;
}

.dashboard-modal .MuiFormControlLabel-root {
  margin-top: 20px;
}

.dashboard-modal .MuiButton-root {
  margin-top: 10px;
}

.dashboard .dashboard-span {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  height: 60px;
}

.dashboard.blurred {
  filter: blur(5px);
}

.dashboard-modal .button-container {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".dashboard{\n  margin-top: 64px;\n}\n\n.dashboard-modal {\n  height: 550px;\n}\n\n.dashboard-modal p {\n  margin-bottom: 10px;\n}\n\n.dashboard-modal .MuiFormControlLabel-root {\n  margin-top: 20px;\n}\n\n.dashboard-modal .MuiButton-root {\n  margin-top: 10px;\n}\n\n.dashboard .dashboard-span {\n  display: flex;\n  flex-direction: row;\n  font-size: 30px;\n  height: 60px;\n}\n\n.dashboard.blurred {\n  filter: blur(5px);\n}\n\n.dashboard-modal .button-container {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
