// src/components/HistoricoTable.tsx
import React, { useState, useEffect } from 'react';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, Box, InputLabel, Select } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'
import api from '../../../services/api';
import { MenuItem, TablePagination } from '@mui/material';

interface Historico {
  evento: string;
  creditos: number;
  id_transacao: string;
  valor: string;
  data_created: Date;
}

const HistoricoTable: React.FC = () => {
  const usuario = JSON.parse(localStorage.getItem('usuario') || '') as { id_marketplace: string };
  const [historicos, setHistoricos] = useState<Historico[]>([]);
  const [filtroEvento, setFiltroEvento] = useState('');
  const [filtroData, setFiltroData] = useState<Date | null>(null);
  const [open, setOpen] = useState(false);
  const [filtroIdTransacao, setFiltroIdTransacao] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchHistoricos();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApplyFilters = () => {
    fetchHistoricos();
    handleClose();
  };

  const resetFilters = () => {
    setFiltroEvento('');
    setFiltroIdTransacao('');
    setFiltroData(null);
  };

  const fetchHistoricos = async () => {
    try {
      const response = await api.get(`/marketplace/historico-membro/${usuario.id_marketplace}`, {
        params: {
          evento: filtroEvento,
          idTransacao: filtroIdTransacao,
          dataEvento: filtroData,
          limit: rowsPerPage,
          page: page + 1
        }
      });

      setHistoricos(response.data);
    } catch (error) {
      console.error('Erro ao buscar históricos', error);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="historico-table-container">
        <div className="historico-table-container" style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
          <Button variant="outlined" color="primary" onClick={handleOpen} style={{ width: '120px' }}>Filtros</Button>
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Filtros</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column">
              <InputLabel id="evento-label">Evento</InputLabel>
              <Select
                labelId="evento-label"
                value={filtroEvento}
                onChange={(event) => setFiltroEvento(event.target.value as string)}
              >
                <MenuItem value=""><em>Nenhum</em></MenuItem>
                <MenuItem value={'A'}>Adicionado</MenuItem>
                <MenuItem value={'D'}>Descontado</MenuItem>
                <MenuItem value={'E'}>Estorno</MenuItem>
              </Select>
              <TextField
                label="ID da Transação"
                value={filtroIdTransacao}
                onChange={(event) => setFiltroIdTransacao(event.target.value)}
              />
              <DatePicker
                label="Data do Evento"
                value={filtroData}
                onChange={(date) => setFiltroData(date)}
                format="dd/MM/yyyy"
                clearable
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetFilters} color="primary">Limpar</Button>
            <Button onClick={handleApplyFilters} color="primary">Aplicar</Button>
          </DialogActions>
        </Dialog>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: '#f5f5f5' }}>
              <TableRow>
                <TableCell>Evento</TableCell>
                <TableCell>Créditos</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>ID da Transação</TableCell>
                <TableCell>Data do Evento</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historicos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((historico, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <span style={{ color: historico.evento === 'A' ? 'green' : historico.evento === 'D' ? 'blue' : 'red' }}>
                      {historico.evento === 'A' && 'Adicionado'}
                      {historico.evento === 'D' && 'Descontado'}
                      {historico.evento === 'E' && 'Estorno'}
                    </span>
                  </TableCell>
                  <TableCell>{Number(historico.creditos).toFixed(1)}</TableCell>
                  <TableCell>{Number(historico.valor) !== 0 ? Number(historico.valor).toFixed(2) : ''}</TableCell>
                  <TableCell>{historico.id_transacao}</TableCell>
                  <TableCell>{new Date(historico.data_created).toLocaleString('pt-BR')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={historicos.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default HistoricoTable;